import React, { useState, useEffect } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Box, Container, useMediaQuery } from "@mui/material";
import theme from "../../theme";

const Layout = ({ type, children, redBg, fullWidth }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box>
      <Header type={type} />
      <Box bgcolor={redBg && theme.palette.background.hfRed}>
        {fullWidth ? (
          <Box
            sx={{
              paddingTop: isSmallScreen ? "70px" : "100px",
              paddingBottom: "64px",
            }}
          >
            {children}
          </Box>
        ) : (
          <Container
            component="main"
            sx={{
              paddingTop: isSmallScreen ? "70px" : "100px",
              paddingBottom: "64px",
            }}
          >
            {children}
          </Container>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
