export default function Language(
  mall = "default",
  activeLanguage = "tr",
  request,
  variant = ""
) {
  const word = {
    default: {
      tr: {
        // header
        createPost: "GÖNDERİ OLUŞTUR",
        // select mall
        mall: "AVM",
        selectMall: "Hands Free’yi Kullanmak İstediğiniz AVM’yi Seçin.",
        continue: "Devam Et",
        continueAndCreate: "Devam Et ve Oluştur",
        // post listing
        welcome: "Hands Free'ye Hoşgeldiniz!",
        signIn: "Lütfen giriş yapın.",
        login: "Giriş Yap",
        enterPhone: "Giriş Yapmak İçin Telefon Numaranızı Girin.",
        storeRequests: "Mağaza Talepleri",
        customerRequests: "Müşteri Talepleri",
        createHandsfree: "{variant} Talebi Oluşturun",
        handsfreeRequests: "{variant} Talepleri",
        emptyStoreRequests:
          "Mağazadan onay bekleyen talebiniz bulunmamaktadır.",
        emptyCustomerRequests: "Sizden onay bekleyen talep bulunmamaktadır.",
        pendingRequests: "Bekleyen Talepleriniz",
        awaitingStore: "Mağazadan onay bekleniyor",
        awaitingYou: "Sizden onay bekleniyor",
        readyPackages: "Teslime Hazır Paketleriniz",
        pickup: "Teslim alınabilir",
        emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
        onthewayPackages: "Yoldaki Paketleriniz",
        ontheway: "Yolda",
        emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
        storePackages: "Mağazadaki Paketler",
        inthestore: "Mağazada",
        emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
        pastRequests: "Geçmiş Talepleriniz",
        completed: "Tamamlandı",
        canceled: "İptal edildi",
        emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
        // last update
        lastUpdate: "Son Güncelleme",
        refresh: "Yenile",
        version: "Versiyon",
        // create request
        selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
        selectStore: "Mağaza Seçin",
        selectPhone: "Lütfen telefon numaranızı girin",
        firm: "Mağaza",
        name: "Ad",
        lastname: "Soyad",
        phone: "Telefon",
        roomNo: "Oda No",
        code: "Kod",
        OTPCode: "OTP Kodunu Girin",
        enterOTP: "Telefonunuza gelen 6 haneli kodu girin.",
        hasCode: "Kod alamadınız mı?",
        codeAgain: "Tekrar kod gönder.",
        enterInformations:
          "Lütfen kullanıcı bilgilerinizi eksiksiz olarak giriniz.",
        conditions1: "{variant} hizmetinden faydalanmak için",
        conditions2: "Kullanım Koşulları",
        conditions3: "ve ücretlendirmeleri kabul ediyorum.",
        taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
        // confirm packages
        confirm: "Onayla ve Devam Et",
        pendingPackages: "Onay Bekleyen Paketleriniz",
        pendingPackagesDescription:
          "Lütfen işlemlerinizin tamamlanması için onaylayın.",
        timeLimit:
          "Hands Free gönderilerimi en az 1 saat sonra alacağımı kabul ediyorum.",
        create: "OLUŞTUR",
        beingCreated: "Oluşturuluyor...",
        cancel: "İptal",
        canceling: "İptal Ediliyor...",
        confirmSuccessMessage:
          "Paketleri onayladınız, iyi alışverişler dileriz.",
        rejectSuccessMessage:
          "Paketler iptal edilmiştir, iyi alışverişler dileriz.",
        // detail
        request: "Talep",
        package: "Paket",
        deliveryInfo: "Teslimat Bilgisi",
        deliveryDetail:
          "Teslimatınız ile ilgili bilgilere aşağıdan ulaşabilirsiniz.",
        deliveryStatus: "Teslimat Durumu",
        unknown: "Bilinmiyor",
        approved: "Onaylandı",
        rejected: "Talep reddedildi",
        deliveryCode: "Teslimat Şifresi",
        creationDate: "Oluşturulma Tarihi",
        store: "Mağaza",
        orderNo: "Sipariş No",
        deadlineForDelivery: "Son Teslim Tarihi",
        navigation: "Yol Tarifi",
        cargoTrackingNo: "Kargo Takip Numarası",
        // modals
        success: "Başarılı",
        successMessage: "Talep başarıyla oluşturulmuştur.",
        error: "Hata",
        errorMessage: "İşlem gerçekleştirilemedi.",
        okay: "Tamam",
        close: "Kapat",
        agree: "Onaylıyorum",
        // Store requests
        storeRequestsText: "Mağaza taleplerinize aşağıdan ulaşabilirsiniz",
        // Customer Requests
        customerRequestsText:
          "Sizden onay bekleyen taleplere aşağıdan ulaşabilirsiniz",
        storePackagesText: "Mağazadaki paketlerinize aşağıdan ulaşabilirsiniz",
        onthewayPackagesText: "Yoldaki paketlerinize aşağıdan ulaşabilirsiniz",
        readyPackagesText:
          "Teslime hazır paketlerinize aşağıdan ulaşabilirsiniz",
        pastRequestsText: "Geçmiş taleplerinize aşağıdan ulaşabilirsiniz",
        consent:
          "6563 sayılı kanun kapsamında, Avm Lojistik Tic. A.Ş tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyonlar, kampanyalar, memnuniyet değerlendirme çalışmaları ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza veriyorum.",
      },
      en: {
        // header
        createPost: "CREATE POST",
        // select mall
        mall: "Shopping Center",
        selectMall: "Select the Mall You Want to Use Hands Free.",
        continue: "Continue",
        continueAndCreate: "Continue and Create",
        // post listing
        welcome: "Welcome to Hands Free!",
        signIn: "Please log in.",
        login: "Login",
        enterPhone: "Enter Your Phone Number To Log In.",
        storeRequests: "Store Requests",
        customerRequests: "Customer Requests",
        createHandsfree: "Create {variant} Request",
        handsfreeRequests: "{variant} Requests",
        emptyStoreRequests:
          "There is no request awaiting approval from the store.",
        emptyCustomerRequests:
          "There are no requests waiting for approval from you",
        pendingRequests: "Pending Requests",
        awaitingStore: "Awaiting approval from the store",
        awaitingYou: "Awaiting approval from you",
        readyPackages: "Packages Ready for Delivery",
        pickup: "Ready for pick-up",
        emptyReadyPackages: "You do not have any packages ready for pick-up.",
        onthewayPackages: "Your Packages on the Road",
        ontheway: "On the Way",
        emptyOnthewayPackages: "You do not have any packages on delivery.",
        storePackages: "Packages In the Store",
        inthestore: "In the Store",
        emptyStorePackages: "You do not have any packages in the store.",
        pastRequests: "Your Past Requests",
        completed: "Completed",
        canceled: "Canceled",
        emptyPastRequests: "You do not have any past requests.",
        // last update
        lastUpdate: "Latest Update",
        refresh: "Refresh",
        version: "Version",
        // create request
        selectStoreTitle:
          "Please select the store you would like to receive service from",
        selectStore: "Select Store",
        selectPhone: "Please enter your telephone number",
        firm: "Store",
        name: "Name",
        lastname: "Lastname",
        phone: "Phone",
        roomNo: "Room No",
        code: "Code",
        OTPCode: "Enter the OTP Code",
        enterOTP: "Enter the 6-digit code received on your phone.",
        hasCode: "You didn't get a code?",
        codeAgain: "Resend code.",
        enterInformations: "Please enter your user information completely.",
        conditions1: "I agree to the",
        conditions2: "conditions",
        conditions3: "and charges for the {variant} service.",
        taxFree: "I would like to use tax refund on my purchases.",
        // confirm packages
        confirm: "Confirm and Continue",
        pendingPackages: "Your Packages Pending Approval",
        pendingPackagesDescription:
          "Please confirm to complete your transaction.",
        timeLimit:
          "I agree to receive my Hands Free packages at least 1 hour later.",
        create: "CREATE",
        beingCreated: "Being Created...",
        cancel: "Cancel",
        canceling: "Canceling...",
        confirmSuccessMessage:
          "You have confirmed the packages, have a good shopping experience.",
        rejectSuccessMessage:
          "Packages have been canceled, we wish you a good shopping experience.",
        // detail
        request: "Request",
        package: "Package",
        deliveryInfo: "Delivery Info",
        deliveryDetail: "You can find information about your delivery below.",
        deliveryStatus: "Delivery Status",
        unknown: "Unknown",
        approved: "Approved",
        rejected: "Rejected",
        deliveryCode: "Delivery Code",
        creationDate: "Creation Date",
        store: "Store",
        orderNo: "Order No",
        deadlineForDelivery: "Deadline for Delivery",
        navigation: "Navigation",
        cargoTrackingNo: "Cargo Tracking Number",
        // modals
        success: "Success",
        successMessage: "The request was successfully created.",
        error: "Error",
        errorMessage: "Transaction could not be processed.",
        okay: "Okay",
        close: "Close",
        agree: "Approve",
        // Store Requests
        storeRequestsText: "You can reach your store requests below",
        customerRequestsText:
          "You can reach the requests waiting for your approval below",
        storePackagesText: "You can access your packages in the store below",
        onthewayPackagesText: "You can reach your packages on the road below",
        readyPackagesText:
          "You can reach your ready-to-delivery packages below",
        pastRequestsText: "You can access your past requests below",
        consent:
          "Within the scope of Law No. 6563, within the scope of the services provided to me by Avm Lojistik Tic. A.Ş., I give my explicit consent to be contacted by any means of communication (such as SMS, e-mail, telephone, fax, call centre, etc.) for the purpose of communicating all kinds of information, promotion, advertisement, product offers, promotions, campaigns, satisfaction evaluation studies and announcements to me.",
      },
    },

    galataport: {
      tr: {
        // header
        createPost: "TALEP OLUŞTUR",
        // select mall
        mall: "AVM",
        selectMall: "Bags-Free’yi Kullanmak İstediğiniz AVM’yi Seçin.",
        continue: "Devam Et",
        continueAndCreate: "Devam Et ve Oluştur",
        // post listing
        welcome: "Bags-Free'ye Hoşgeldiniz!",
        signIn: "Lütfen giriş yapın.",
        login: "Giriş Yap",
        enterPhone: "Giriş Yapmak İçin Telefon Numaranızı Girin.",
        storeRequests: "Mağaza Talepleri",
        customerRequests: "Müşteri Talepleri",
        createHandsfree: "{variant} Talebi Oluşturun",
        handsfreeRequests: "{variant} Talepleri",
        emptyStoreRequests:
          "Mağazadan onay bekleyen talebiniz bulunmamaktadır.",
        emptyCustomerRequests: "Sizden onay bekleyen talep bulunmamaktadır.",
        pendingRequests: "Bekleyen Talepleriniz",
        awaitingStore: "Mağazadan onay bekleniyor",
        awaitingYou: "Sizden onay bekleniyor",
        readyPackages: "Teslime Hazır Paketleriniz",
        pickup: "Teslim alınabilir",
        emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
        onthewayPackages: "Yoldaki Paketleriniz",
        ontheway: "Yolda",
        emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
        storePackages: "Mağazadaki Paketler",
        inthestore: "Mağazada",
        emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
        pastRequests: "Geçmiş Talepleriniz",
        completed: "Tamamlandı",
        canceled: "İptal edildi",
        emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
        // last update
        lastUpdate: "Son Güncelleme",
        refresh: "Yenile",
        version: "Versiyon",
        // create request
        selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
        selectStore: "Mağaza Seçin",
        selectPhone: "Lütfen telefon numaranızı girin",
        firm: "Mağaza",
        name: "Ad",
        lastname: "Soyad",
        phone: "Telefon",
        roomNo: "Oda No",
        code: "Kod",
        OTPCode: "OTP Kodunu Girin",
        enterOTP: "Telefonunuza gelen 6 haneli kodu girin.",
        hasCode: "Kod alamadınız mı?",
        codeAgain: "Tekrar kod gönder.",
        enterInformations:
          "Lütfen kullanıcı bilgilerinizi eksiksiz olarak giriniz.",
        conditions1: "{variant} hizmetinden faydalanmak için",
        conditions2: "Kullanım Koşulları",
        conditions3: "ve ücretlendirmeleri kabul ediyorum.",
        taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
        // confirm packages
        confirm: "Onayla ve Devam Et",
        pendingPackages: "Onay Bekleyen Paketleriniz",
        pendingPackagesDescription:
          "Lütfen işlemlerinizin tamamlanması için onaylayın.",
        timeLimit:
          "Bags-Free gönderilerimi en az 1 saat sonra alacağımı kabul ediyorum.",
        create: "TALEBİNİZİ ONAYLAYIN",
        beingCreated: "Oluşturuluyor...",
        cancel: "İptal",
        canceling: "İptal Ediliyor...",
        confirmSuccessMessage:
          "Paketleri onayladınız, iyi alışverişler dileriz.",
        rejectSuccessMessage:
          "Paketler iptal edilmiştir, iyi alışverişler dileriz.",
        // detail
        request: "Talep",
        package: "Paket",
        deliveryInfo: "Teslimat Bilgisi",
        deliveryDetail:
          "Teslimatınız ile ilgili bilgilere aşağıdan ulaşabilirsiniz.",
        deliveryStatus: "Teslimat Durumu",
        unknown: "Bilinmiyor",
        approved: "Onaylandı",
        rejected: "Talep reddedildi",
        deliveryCode: "Teslimat Şifresi",
        creationDate: "Oluşturulma Tarihi",
        store: "Mağaza",
        orderNo: "Sipariş No",
        deadlineForDelivery: "Son Teslim Tarihi",
        navigation: "Yol Tarifi",
        cargoTrackingNo: "Kargo Takip Numarası",
        paymentStatus: "Ödeme Durumu",
        paid: "Ödenmiş",
        cash: "Nakit",
        creditCard: "Kredi Kartı",
        unpaid: "Ödenmemiş",
        paymentMethod: "Ödeme Yöntemi",
        online: "Online",
        cashOrPost: "Nakit veya post",
        // modals
        success: "Başarılı",
        successMessage: "Talep başarıyla oluşturulmuştur.",
        error: "Hata",
        errorMessage: "İşlem gerçekleştirilemedi.",
        okay: "Tamam",
        close: "Kapat",
        agree: "Onaylıyorum",
        storeRequestsText: "Mağaza taleplerinize aşağıdan ulaşabilirsiniz",
        customerRequestsText:
          "Sizden onay bekleyen taleplere aşağıdan ulaşabilirsiniz",
        storePackagesText: "Mağazadaki paketlerinize aşağıdan ulaşabilirsiniz",
        onthewayPackagesText: "Yoldaki paketlerinize aşağıdan ulaşabilirsiniz",
        readyPackagesText:
          "Teslime hazır paketlerinize aşağıdan ulaşabilirsiniz",
        pastRequestsText: "Geçmiş taleplerinize aşağıdan ulaşabilirsiniz",
        consent:
          "6563 sayılı kanun kapsamında, Avm Lojistik Tic. A.Ş tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza veriyorum.",
      },
      en: {
        // header
        createPost: "CREATE REQUEST",
        // select mall
        mall: "Shopping Center",
        selectMall: "Select the Mall You Want to Use Bags-Free.",
        continue: "Continue",
        continueAndCreate: "Continue and Create",
        // post listing
        welcome: "Welcome to Bags-Free!",
        signIn: "Please log in.",
        login: "Login",
        enterPhone: "Enter Your Phone Number To Log In.",
        storeRequests: "Store Requests",
        customerRequests: "Customer Requests",
        createHandsfree: "Create {variant} Request",
        handsfreeRequests: "{variant} Requests",
        emptyStoreRequests:
          "There is no request awaiting approval from the store.",
        emptyCustomerRequests:
          "There are no requests waiting for approval from you",
        pendingRequests: "Pending Requests",
        awaitingStore: "Awaiting approval from the store",
        awaitingYou: "Awaiting approval from you",
        readyPackages: "Packages Ready for Delivery",
        pickup: "Ready for pick-up",
        emptyReadyPackages: "You do not have any packages ready for pick-up.",
        onthewayPackages: "Your Packages on the Road",
        ontheway: "On the Way",
        emptyOnthewayPackages: "You do not have any packages on delivery.",
        storePackages: "Packages In the Store",
        inthestore: "In the Store",
        emptyStorePackages: "You do not have any packages in the store.",
        pastRequests: "Your Past Requests",
        completed: "Completed",
        canceled: "Canceled",
        emptyPastRequests: "You do not have any past requests.",
        // last update
        lastUpdate: "Latest Update",
        refresh: "Refresh",
        version: "Version",
        // create request
        selectStoreTitle:
          "Please select the store you would like to receive service from",
        selectStore: "Select Store",
        selectPhone: "Please enter your telephone number",
        firm: "Store",
        name: "Name",
        lastname: "Lastname",
        phone: "Phone",
        roomNo: "Room No",
        code: "Code",
        OTPCode: "Enter the OTP Code",
        enterOTP: "Enter the 6-digit code received on your phone.",
        hasCode: "You didn't get a code?",
        codeAgain: "Resend code.",
        enterInformations: "Please enter your user information completely.",
        conditions1: "I agree to the",
        conditions2: "conditions",
        conditions3: "and charges for the {variant} service.",
        taxFree: "I would like to use tax refund on my purchases.",
        // confirm packages
        confirm: "Confirm and Continue",
        pendingPackages: "Your Packages Pending Approval",
        pendingPackagesDescription:
          "Please confirm to complete your transaction.",
        timeLimit:
          "I agree to receive my Bags-Free packages at least 1 hour later.",
        create: "APPROVE",
        beingCreated: "Being Created...",
        cancel: "Cancel",
        canceling: "Canceling...",
        confirmSuccessMessage:
          "You have confirmed the packages, have a good shopping experience.",
        rejectSuccessMessage:
          "Packages have been canceled, we wish you a good shopping experience.",
        // detail
        request: "Request",
        package: "Package",
        deliveryInfo: "Delivery Info",
        deliveryDetail: "You can find information about your delivery below.",
        deliveryStatus: "Delivery Status",
        unknown: "Unknown",
        approved: "Approved",
        rejected: "Rejected declined",
        deliveryCode: "Delivery Code",
        creationDate: "Creation Date",
        store: "Store",
        orderNo: "Order No",
        deadlineForDelivery: "Deadline for Delivery",
        navigation: "Navigation",
        cargoTrackingNo: "Cargo Tracking Number",
        paymentStatus: "Payment Status",
        paid: "Paid",
        cash: "Cash",
        creditCard: "Credit Card",
        unpaid: "Unpaid",
        paymentMethod: "Payment Method",
        online: "Online",
        cashOrPost: "Cash or post",
        // modals
        success: "Success",
        successMessage: "The request was successfully created.",
        error: "Error",
        errorMessage: "Transaction could not be processed.",
        okay: "Okay",
        close: "Close",
        agree: "Approve",
        storeRequestsText: "You can reach your store requests below",
        customerRequestsText:
          "You can reach the requests waiting for your approval below",
        storePackagesText: "You can access your packages in the store below",
        onthewayPackagesText: "You can reach your packages on the road below",
        readyPackagesText:
          "You can reach your ready-to-delivery packages below",
        pastRequestsText: "You can access your past requests below",
        consent:
          "Within the scope of Law No. 6563, within the scope of the services provided to me by Avm Lojistik Tic. A.Ş., I give my explicit consent to be contacted by any means of communication (such as SMS, e-mail, telephone, fax, call centre, etc.) for the purpose of communicating all kinds of information and announcements to me.",
      },
    },
  };

  return typeof word[mall] == "undefined"
    ? word["default"][activeLanguage][request]?.replace("{variant}", variant)
    : word[mall][activeLanguage][request]?.replace("{variant}", variant);
}
