import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";
import Layout from "../components/layout/Layout.";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import { useDispatch, useSelector } from "react-redux";
import Language from "../languages";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PrimaryButton } from "../components/Button";
import { setMall as setReduxMall, setProjectName } from "../redux/appReducer";

export default function SelectMallPage() {
  const storedPhone = localStorage.getItem("phone");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const [token_, setToken_] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [malls, setMalls] = useState([]);
  const [mall, setMall] = useState("");

  const handleContinue = () => {
    setShowLoading(true);
    localStorage.setItem("mallUri", mall);
    Services.GetMallToken(token_, mall)
      .then((response) => {
        localStorage.setItem("mall", JSON.stringify(response.data.result.mall));
        console.log("response.data: ", response.data);
        dispatch(setReduxMall(mall));
        dispatch(
          setProjectName(mall === "galataport" ? "galataport" : "default")
        );
        if (storedPhone) {
          navigate(`/${mall}`);
        } else {
          navigate("/login");
        }
      })
      .catch(() => {
        // console.log("mall token hata");
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken_(token);
    Services.GetMalls(token)
      .then((res) => {
        console.log(res.data);
        setMalls(res.data.result);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <Layout type="logo">
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(_mall, language, "welcome")}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(_mall, language, "signIn")}
        </Typography>
        <Divider
          sx={{
            marginTop: "15px",
            color: "#EAECF0",
            // display: { xs: "block", md: "none" },
          }}
        />
        <Box
          sx={{
            marginBottom: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            // textAlign="center"
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 600,
              marginBlock: 4,
              color: "#475467",
            }}
          >
            {Language(_mall, language, "selectMall")}
          </Typography>

          {!showLoading && (
            <FormControl fullWidth>
              <InputLabel
                style={{ color: "#C1C6C9", fontSize: 15 }}
                id="demo-simple-select-label"
              >
                {Language(_mall, language, "mall")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="AVM"
                name="avm"
                value={mall}
                onChange={(e) => setMall(e.target.value)}
                IconComponent={ExpandMoreIcon}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "rgba(208, 213, 221, 1)",
                  borderRadius: "6px",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                {malls?.map((mall) => (
                  <MenuItem key={mall.id} value={mall.uri}>
                    {mall.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <PrimaryButton
          text={Language(_mall, language, "continue")}
          loading={showLoading}
          disabled={showLoading || mall === ""}
          handlePress={handleContinue}
        />
      </Box>
    </Layout>
  );
}
