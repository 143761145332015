import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const CustomButton = styled(Button)(({ theme, disabled, opposite, faint }) => ({
  backgroundColor: opposite
    ? "white"
    : disabled || faint
    ? "rgba(208, 213, 221, 1)"
    : "rgba(233, 36, 52, 1)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  height: "50px",
  color: opposite ? "rgba(233, 36, 52, 1)" : "#ffffff !important",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 600,
  borderRadius: "8px",
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: disabled
      ? "rgba(234, 236, 240, 1)"
      : "rgba(233, 36, 52, 0.8)",
  },
  paddingY: 1.2,
}));

const PrimaryButton = ({
  text,
  loading,
  disabled,
  handlePress,
  opposite,
  sx,
}) => {
  return (
    <CustomButton
      variant="contained"
      disabled={disabled || loading}
      onClick={handlePress}
      fullWidth
      opposite={opposite}
      sx={sx}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : text}
    </CustomButton>
  );
};

const FaintButton = ({
  text,
  loading,
  disabled,
  handlePress,
  opposite,
  faint,
  sx,
}) => {
  return (
    <CustomButton
      variant="contained"
      disabled={disabled || loading}
      onClick={handlePress}
      fullWidth
      opposite={opposite}
      faint={faint}
      sx={sx}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : text}
    </CustomButton>
  );
};

const LeftIconButton = ({
  text,
  loading,
  disabled,
  handlePress,
  opposite,
  sx,
}) => {
  return (
    <CustomButton
      variant="contained"
      disabled={disabled || loading}
      onClick={handlePress}
      fullWidth
      opposite={opposite}
      sx={sx}
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <>
          <SendIcon
            sx={{
              marginRight: 1,
              color: opposite ? "rgba(233, 36, 52, 1)" : "white",
            }}
          />
          {text}
        </>
      )}
    </CustomButton>
  );
};

const RightIconButton = ({
  text,
  loading,
  disabled,
  handlePress,
  opposite,
  sx,
}) => {
  return (
    <CustomButton
      variant="contained"
      disabled={disabled || loading}
      onClick={handlePress}
      fullWidth
      opposite={opposite}
      sx={sx}
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <>
          {text}
          <AddCircleOutlineOutlinedIcon
            sx={{
              marginLeft: 1,
              color: opposite ? "rgba(233, 36, 52, 1)" : "white",
            }}
          />
        </>
      )}
    </CustomButton>
  );
};

export { PrimaryButton, LeftIconButton, RightIconButton, FaintButton };
