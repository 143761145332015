import React, { useState, useEffect } from "react";
import { ArrowBackIosNew, ArrowDropDown } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FlagImage } from "react-international-phone";
import {
  Typography,
  Avatar,
  IconButton,
  Grid,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Divider,
  Container,
  AppBar,
  Toolbar,
} from "@mui/material";
import Logo from "../../../assets/images/hf.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../redux/appReducer";
import Services from "../../../services/Services";
import { useNavigate } from "react-router-dom";

export default function Header({ type }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const storedName = localStorage.getItem("name");
  const storedLastname = localStorage.getItem("lastname");
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const initials =
    storedName &&
    storedName.trim() !== "" &&
    storedLastname &&
    storedLastname.trim() !== ""
      ? storedName.charAt(0).toUpperCase() +
        storedLastname.charAt(0).toUpperCase()
      : "HF";

  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    const token = localStorage.getItem("token");
    const customerID = localStorage.getItem("customerID");
    if (token && customerID) {
      try {
        const response = await Services.SetLanguage(token, customerID, lng);
        console.log("SetLanguage başarılı:", response.data);
      } catch (error) {
        console.error("SetLanguage API çağrısı başarısız:", error);
      }
    }
    handleMenuClose();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {isSmallScreen ? (
        <>
          <div
            style={{ position: "fixed", backgroundColor: "white", zIndex: 999 }}
          >
            <Container>
              <Box>
                <Grid
                  container
                  alignItems="center"
                  sx={{ paddingBlock: "8px" }}
                >
                  {type === "backButton" && (
                    <>
                      <Grid item xs={1}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <IconButton
                            aria-label="goBack"
                            size="small"
                            onClick={handleGoBack}
                            sx={{
                              border: "1px solid #e0e0e0",
                              borderRadius: "6px",
                              padding: "8px",
                              boxShadow:
                                "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            }}
                          >
                            <ArrowBackIcon
                              sx={{ color: "#3f3f3f", fontSize: "20px" }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid item xs={8} />
                      <Grid item xs={3} onClick={handleMenuClick}>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              bgcolor: "rgba(242, 244, 247, 1)",
                              border: "1px solid rgba(208, 213, 221, 1)",
                              borderRadius: "3px",
                              cursor: "pointer",
                              padding: "2px 4px",
                              marginRight: 2,
                            }}
                          >
                            <FlagImage
                              iso2={language === "tr" ? "tr" : "us"}
                              size="26px"
                            />
                            <ArrowDropDown />
                          </Box>
                          <Avatar
                            sx={{
                              bgcolor: "rgba(242, 244, 247, 1)",
                              width: isSmallScreen ? 30 : 34,
                              height: isSmallScreen ? 30 : 34,
                              cursor: "pointer",
                              border: "solid",
                              borderWidth: "1px",
                              borderRadius: 17,
                              borderColor: "rgba(208, 213, 221, 1)",
                            }}
                          >
                            <Typography
                              sx={{
                                lineHeight: isSmallScreen
                                  ? "10.75px"
                                  : "14.75px",
                                fontSize: isSmallScreen ? 10 : 12,
                                fontWeight: 600,
                                color: "rgba(71, 84, 103, 1)",
                              }}
                            >
                              {initials}
                            </Typography>
                          </Avatar>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                dispatch(setLanguage("en"));
                                changeLanguage("en");
                              }}
                            >
                              English
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(setLanguage("tr"));
                                changeLanguage("tr");
                              }}
                            >
                              Türkçe
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {type === "logo" && (
                    <>
                      <Grid item xs={1} onClick={handleMenuClick}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              bgcolor: "rgba(242, 244, 247, 1)",
                              border: "1px solid rgba(208, 213, 221, 1)",
                              borderRadius: "3px",
                              cursor: "pointer",
                              padding: "2px 4px",
                              marginRight: 2,
                            }}
                          >
                            <FlagImage
                              iso2={language === "tr" ? "tr" : "us"}
                              size="26px"
                            />
                            <ArrowDropDown />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={10}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            src={Logo}
                            alt="easypoint logo"
                            width={isSmallScreen ? 160 : 180}
                            style={{ marginTop: 5 }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Avatar
                            sx={{
                              bgcolor: "rgba(242, 244, 247, 1)",
                              width: isSmallScreen ? 28 : 32,
                              height: isSmallScreen ? 28 : 32,
                              cursor: "pointer",
                              border: "solid",
                              borderWidth: "1px",
                              borderRadius: 16,
                              borderColor: "rgba(208, 213, 221, 1)",
                            }}
                          >
                            <Typography
                              sx={{
                                lineHeight: isSmallScreen
                                  ? "10.75px"
                                  : "14.75px",
                                fontSize: isSmallScreen ? 10 : 12,
                                fontWeight: 600,
                                color: "rgba(71, 84, 103, 1)",
                              }}
                            >
                              {initials}
                            </Typography>
                          </Avatar>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                dispatch(setLanguage("en"));
                                changeLanguage("en");
                              }}
                            >
                              English
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(setLanguage("tr"));
                                changeLanguage("tr");
                              }}
                            >
                              Türkçe
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Container>

            <Box sx={{ width: "100vw", position: "relative", left: 0 }}>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#e0e0e0",
                  marginTop: "1px",
                }}
              />
            </Box>
          </div>
        </>
      ) : (
        <AppBar
          position="static"
          style={{
            backgroundColor: "#fff",
            boxShadow: "2px 4px 5.5px 0px rgba(0, 0, 0, 0.05)",
            zIndex: 999,
            position: "fixed",
          }}
        >
          <Toolbar sx={{ marginInline: "10%" }}>
            <Grid container alignItems="center">
              <Grid item xs={4} sm={2}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    src={Logo}
                    alt="easypoint logo"
                    width={200}
                    style={{ marginTop: 5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} sm={10}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box
                    onClick={handleMenuClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "rgba(249, 250, 251, 1)",
                      border: "1px solid rgba(208, 213, 221, 1)",
                      borderRadius: "3px",
                      cursor: "pointer",
                      padding: "5px 4px",
                      marginRight: 2,
                    }}
                  >
                    <FlagImage
                      iso2={language === "tr" ? "tr" : "us"}
                      size="30px"
                    />
                    <ArrowDropDown style={{ color: "rgba(90, 91, 92, 1)" }} />
                  </Box>
                  {storedName && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        bgcolor: "white",
                        border: "1px solid rgba(208, 213, 221, 1)",
                        borderRadius: "8px",
                        cursor: "pointer",
                        padding: "5px 10px",
                        marginLeft: "5px",
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "rgba(218, 56, 49, 1)",
                          width: 32,
                          height: 32,
                          cursor: "pointer",
                          marginRight: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 600,
                            color: "white",
                          }}
                        >
                          {initials}
                        </Typography>
                      </Avatar>
                      <Box>
                        <Typography
                          sx={{ fontSize: 14, fontWeight: 600, color: "#000" }}
                        >
                          Kullanıcı
                        </Typography>
                        <Typography
                          sx={{ fontSize: 12, color: "rgba(123, 125, 143, 1)" }}
                        >
                          Hands Free
                        </Typography>
                      </Box>
                      <ArrowDropDown
                        sx={{ color: "rgba(123, 125, 143, 1)", marginLeft: 1 }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                dispatch(setLanguage("en"));
                changeLanguage("en");
              }}
            >
              English
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(setLanguage("tr"));
                changeLanguage("tr");
              }}
            >
              Türkçe
            </MenuItem>
          </Menu>
        </AppBar>
      )}
    </>
  );
}
