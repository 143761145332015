import {
  IconButton,
  Button,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PinDropOutlined, LockOpen } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useSelector } from "react-redux";
import Language from "../languages";
import Layout from "../components/layout/Layout.";

export default function PostDetailPage() {
  const navigate = useNavigate();
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const { index } = useParams();
  const location = useLocation();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const item = location.state;
  const [dialogOpen, setDialogOpen] = useState(false);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return moment(date).format("DD.MM.YYYY HH:mm");
  };

  const padWithZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = padWithZero(currentDate.getDate());
    const month = padWithZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    return `${day}.${month}.${year}`;
  };
  const deadlineDate = getCurrentDate();
  const deadlineTime = "22:00";

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout type={"backButton"} redBg={isWideScreen}>
      {isWideScreen && (
        <Box
          display="flex"
          sx={{ maxWidth: "550px", marginBottom: "20px" }}
          justifyContent="space-between"
          alignItems="center"
          marginX={"auto"}
        >
          <IconButton
            aria-label="goBack"
            size="small"
            onClick={() => navigate(-1)}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "6px",
              padding: "8px",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              backgroundColor: "white",
            }}
          >
            <ArrowBackIcon sx={{ color: "#3f3f3f", fontSize: "20px" }} />
          </IconButton>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
          >
            {Language(mall, language, "welcome")}
          </Typography>
          <Box />
        </Box>
      )}
      <Box
        // sx={{ transform: "translateY(40%)", msTransform: "translateY(40%)" }}
        sx={{
          backgroundColor: isWideScreen ? "white" : "transparent",
          borderRadius: isWideScreen ? "6px" : "0px",
          borderColor: isWideScreen ? "rgba(241, 235, 235, 1)" : "transparent",
          border: isWideScreen ? "1px" : "none",
          maxWidth: isWideScreen ? "550px" : null,
          padding: isWideScreen ? 3 : 0,
          marginBottom: isWideScreen ? "300px" : "0px",
          marginX: isWideScreen ? "auto" : "0",
        }}
      >
        <Typography
          textAlign="left"
          sx={{
            lineHeight: "16.94px",
            fontSize: 14,
            fontWeight: 600,
            // marginX: 2,
          }}
        >
          {Language(mall, language, "deliveryInfo")}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            // textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "deliveryDetail")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            marginBlock: "15px",
          }}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginX: 2 }}
        >
          <Typography
            textAlign="left"
            color="rgba(16, 24, 40, 1)"
            sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
          >
            {Language(mall, language, "deliveryStatus")}
          </Typography>
          <Typography
            textAlign="left"
            color="rgba(71, 84, 103, 1)"
            sx={{ lineHeight: "19.07px", fontSize: 13, fontWeight: 400 }}
          >
            {item.postID
              ? item.status === "3"
                ? Language(mall, language, "inthestore")
                : item.status === "4"
                ? Language(mall, language, "ontheway")
                : item.status === "5"
                ? Language(mall, language, "pickup")
                : item.status === "1"
                ? Language(mall, language, "completed")
                : item.status === "2"
                ? Language(mall, language, "canceled")
                : Language(mall, language, "unknown")
              : item.status === "2"
              ? Language(mall, language, "rejected")
              : item.status === "3"
              ? Language(mall, language, "awaitingStore")
              : item.status === "1"
              ? Language(mall, language, "approved")
              : Language(mall, language, "unknown")}
          </Typography>
        </Box>
        <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
        {item.postID && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginX: 2 }}
            >
              <Typography
                textAlign="left"
                color="rgba(16, 24, 40, 1)"
                sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
              >
                {Language(mall, language, "deliveryCode")}
              </Typography>
              <Typography
                textAlign="left"
                sx={{
                  lineHeight: "16.94px",
                  fontSize: 13,
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(71, 84, 103, 1)",
                }}
              >
                <LockOpen sx={{ marginRight: 1, width: 18 }} />{" "}
                {item.deliveryPassword}
              </Typography>
            </Box>
            <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          </>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginX: 2 }}
        >
          <Typography
            textAlign="left"
            color="rgba(16, 24, 40, 1)"
            sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
          >
            {Language(mall, language, "creationDate")}
          </Typography>
          <Typography
            textAlign="left"
            sx={{
              lineHeight: "16.94px",
              fontSize: 13,
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              color: "rgba(71, 84, 103, 1)",
            }}
          >
            {formatDateTime(item.createdDateTime)}
          </Typography>
        </Box>
        <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginX: 2 }}
        >
          <Typography
            textAlign="left"
            color="rgba(16, 24, 40, 1)"
            sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
          >
            {Language(mall, language, "store")}
          </Typography>
          <Typography
            textAlign="left"
            sx={{
              lineHeight: "16.94px",
              fontSize: 13,
              fontWeight: 400,
              color: "rgba(71, 84, 103, 1)",
            }}
          >
            {item.branchTitle}
          </Typography>
        </Box>
        <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginX: 2 }}
        >
          <Typography
            textAlign="left"
            color="rgba(16, 24, 40, 1)"
            sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
          >
            Easy Point
          </Typography>
          <div
            onClick={() => {
              if (item.mallTitle === "Galataport") {
                setDialogOpen(true);
              } else {
                return null;
              }
            }}
            style={{
              cursor: "pointer",
              color: "rgba(206, 95, 90, 1)",
              paddingBlock: 5,
              paddingInline: 8,
              // width: 100,
              backgroundColor: "rgba(244, 243, 255, 1)",
              border: "1px solid rgba(217, 214, 254, 1)",
              borderRadius: 21,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Typography
              textAlign="left"
              sx={{
                lineHeight: "16.94px",
                fontSize: 14,
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                color: "rgba(89, 37, 220, 1)",
                paddingTop: "2px",
              }}
            >
              {Language(mall, language, "navigation")}
            </Typography>
            <LocationOnOutlinedIcon
              sx={{
                marginLeft: "3px",
                width: 18,
                color: "rgba(89, 37, 220, 1)",
              }}
            />
          </div>
        </Box>
        <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginX: 2 }}
        >
          <Typography
            textAlign="left"
            color="rgba(16, 24, 40, 1)"
            sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
          >
            {Language(mall, language, "orderNo")}
          </Typography>
          <Typography
            textAlign="left"
            sx={{
              lineHeight: "16.94px",
              fontSize: 13,
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              color: "rgba(71, 84, 103, 1)",
            }}
          >
            {item.orderID}
          </Typography>
        </Box>
        {item.postID && (
          <>
            <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginX: 2 }}
            >
              <Typography
                textAlign="left"
                color="rgba(16, 24, 40, 1)"
                sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
              >
                {Language(mall, language, "cargoTrackingNo")}
              </Typography>
              <Typography
                textAlign="left"
                sx={{
                  lineHeight: "16.94px",
                  fontSize: 13,
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(71, 84, 103, 1)",
                }}
              >
                {item.postID}
              </Typography>
            </Box>
          </>
        )}
        {mall === "galataport" && item.isPaid === "1" && (
          <>
            <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginX: 2 }}
            >
              <Typography
                textAlign="left"
                color="rgba(16, 24, 40, 1)"
                sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
              >
                {Language(mall, language, "paymentMethod")}
              </Typography>
              <Typography
                textAlign="left"
                sx={{
                  lineHeight: "16.94px",
                  fontSize: 13,
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(71, 84, 103, 1)",
                }}
              >
                {item.paymentType === "cash"
                  ? Language(mall, language, "cash")
                  : item.paymentType === "credit-card"
                  ? Language(mall, language, "creditCard")
                  : item.paymentType === "online"
                  ? Language(mall, language, "online")
                  : Language(mall, language, "cashOrPost")}
              </Typography>
            </Box>

            <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginX: 2 }}
            >
              <Typography
                textAlign="left"
                color="rgba(16, 24, 40, 1)"
                sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
              >
                {Language(mall, language, "paymentStatus")}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBlock: 5,
                  paddingInline: 8,
                  border: "1px solid rgba(171, 239, 198, 1)",
                  backgroundColor: "rgba(236, 253, 243, 1)",
                  borderRadius: 16,
                }}
              >
                <div
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: "rgba(6, 118, 71, 1)",
                    marginRight: 6,
                  }}
                />
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 13,
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    color: "rgba(6, 118, 71, 1)",
                  }}
                >
                  {Language(mall, language, "paid")}
                </Typography>
              </div>
            </Box>
          </>
        )}
        {mall === "galataport" && item.isPaid === "0" && (
          <>
            <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginX: 2 }}
            >
              <Typography
                textAlign="left"
                color="rgba(16, 24, 40, 1)"
                sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
              >
                {Language(mall, language, "paymentStatus")}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBlock: 5,
                  paddingInline: 8,
                  border: "1px solid rgba(254, 205, 202, 1)",
                  backgroundColor: "rgba(254, 243, 242, 1)",
                  borderRadius: 16,
                }}
              >
                <div
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: "rgba(240, 68, 56, 1)",
                    marginRight: 6,
                  }}
                />
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 13,
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    color: "rgba(240, 68, 56, 1)",
                  }}
                >
                  {Language(mall, language, "unpaid")}
                </Typography>
              </div>
            </Box>
          </>
        )}
        <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
        {(item.status === "3" ||
          item.status === "4" ||
          item.status === "5") && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginX: 2 }}
            >
              <Typography
                textAlign="left"
                color="rgba(16, 24, 40, 1)"
                sx={{ lineHeight: "14.52px", fontSize: 13, fontWeight: 500 }}
              >
                {Language(mall, language, "deadlineForDelivery")}
              </Typography>
              <Typography
                textAlign="left"
                sx={{
                  lineHeight: "16.94px",
                  fontSize: 13,
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(71, 84, 103, 1)",
                }}
              >
                {`${deadlineDate} ${deadlineTime}`}
              </Typography>
            </Box>
            <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          </>
        )}
      </Box>
      <Dialog
        fullWidth
        fullScreen
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{item.mallTitle}</DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            style={{
              border: 0,
            }}
            src={`https://inmapper.com/galataport/?id=ID0D014&toTitle=Easy%20Point`}
          ></iframe>
        </DialogContent>
        <DialogActions
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            padding: "6px",
            background: "#d12626",
            borderRadius: "5px",
          }}
        >
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            style={{ color: "white" }}
          >
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
