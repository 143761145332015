import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { Phone, Email } from "@mui/icons-material";
import Language from "../../../languages";
import { useSelector } from "react-redux";

const Footer = () => {
  const isSmallScreen = useMediaQuery("(max-width:480px)");
  const language = useSelector((state) => state.language);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "10px 0",
        backgroundColor: "#f8f9fa",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <Box display="flex" alignItems="center">
            <Phone
              sx={{
                color: "rgba(152, 162, 179, 1)",
                marginRight: "8px",
                fontSize: isSmallScreen ? "18px" : "24px",
              }}
            />
            <Typography
              sx={{
                color: "rgba(152, 162, 179, 1)",
                fontSize: isSmallScreen ? "12px" : "14px",
              }}
            >
              0850 214 0044
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center">
            <Email
              sx={{
                color: "rgba(152, 162, 179, 1)",
                marginRight: "8px",
                fontSize: isSmallScreen ? "18px" : "24px",
              }}
            />
            <Typography
              sx={{
                color: "rgba(152, 162, 179, 1)",
                fontSize: isSmallScreen ? "12px" : "14px",
              }}
            >
              info@easypoint.com.tr
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" marginTop="8px">
        <Typography
          sx={{
            color: "rgba(152, 162, 179, 1)",
            fontSize: isSmallScreen ? "12px" : "14px",
          }}
        >
          {Language("default", language, "version")} 2.0.0
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
