import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Language from "../../languages";
import { useSelector } from "react-redux";
import CustomCard from "./Card";

const RedBackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(218, 56, 49, 1)",
  padding: theme.spacing(2),
  minHeight: "100vh",
  marginTop: "30px",
}));

const Content = ({
  storeRequestsLength,
  customerRequestsLength,
  inStorePackagesLength,
  inTransitPackagesLength,
  readyForDeliveryPackagesLength,
  pastRequestsLength,
}) => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);

  const initialData = [
    {
      type: "storeRequests",
      title: Language(mall, language, "storeRequests"),
      subtitle: Language(mall, language, "emptyStoreRequests"),
      icon: "ShoppingBagOutlined",
      path: "store-requests",
    },
    {
      type: "customerRequests",
      title: Language(mall, language, "customerRequests"),
      subtitle: Language(mall, language, "emptyCustomerRequests"),
      icon: "PersonOutlined",
      path: "customer-requests",
    },
    {
      type: "storePackages",
      title: Language(mall, language, "storePackages"),
      subtitle: Language(mall, language, "emptyStorePackages"),
      icon: "StorefrontOutlined",
      path: "store-packages",
    },
    {
      type: "packagesOnTheRoad",
      title: Language(mall, language, "onthewayPackages"),
      subtitle: Language(mall, language, "emptyOnthewayPackages"),
      icon: "NearMeOutlined",
      path: "packages-in-transit",
    },
    {
      type: "readyPackages",
      title: Language(mall, language, "readyPackages"),
      subtitle: Language(mall, language, "emptyReadyPackages"),
      icon: "TaskAltOutlined",
      path: "deliverable-packages",
    },
    {
      type: "pastRequests",
      title: Language(mall, language, "pastRequests"),
      subtitle: Language(mall, language, "emptyPastRequests"),
      icon: "HistoryOutlined",
      path: "past-requests",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const badges = [
    storeRequestsLength,
    customerRequestsLength,
    inStorePackagesLength,
    inTransitPackagesLength,
    readyForDeliveryPackagesLength,
    pastRequestsLength,
  ];

  const data = initialData.map((item, index) => ({
    ...item,
    badge: badges[index] || 0,
  }));

  return (
    <RedBackgroundBox>
      <Box display="flex" justifyContent={isWideScreen ? "flex-end" : "center"}>
        {storeRequestsLength + customerRequestsLength > 0 && (
          <Stack
            display="flex"
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems="center"
            mb={2}
            sx={{
              backgroundColor: "rgba(254, 228, 226, 1)",
              padding: "4px 4px 4px 12px",
              width: "160px",
              borderRadius: "16px",
              marginX: isWideScreen ? "0" : "auto",
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500" }}
              color="rgba(180, 35, 24, 1)"
            >
              {Language(mall, language, "pendingRequests")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(217, 45, 32, 1)",
                borderRadius: "10px",
                width: "20px",
                height: "20px",
                marginLeft: "8px",
              }}
            >
              <Typography
                sx={{ fontSize: "12px", textAlign: "center" }}
                color="white"
              >
                {storeRequestsLength + customerRequestsLength}
              </Typography>
            </Box>
          </Stack>
        )}
      </Box>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <CustomCard
              title={item.title}
              badge={item.badge}
              subtitle={item.subtitle}
              icon={item.icon}
              type={item.type}
              language={language}
              path={item.path}
            />
          </Grid>
        ))}
      </Grid>
    </RedBackgroundBox>
  );
};

export default Content;
