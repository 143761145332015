import React, { useState, useEffect } from "react";
import { ArrowBackIosNew, ArrowDropDown } from "@mui/icons-material";
import {
  Typography,
  Avatar,
  IconButton,
  Grid,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Divider,
  Container,
} from "@mui/material";
import Logo from "../assets/images/hf.png";
import { useTranslation } from "react-i18next";
import UnitedKingdom from "../assets/images/united-kingdom.png";
import Turkiye from "../assets/images/turkey.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/appReducer";
import Services from "../services/Services";
import { useNavigate } from "react-router-dom";

export default function HeaderComponent({ type }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const storedName = localStorage.getItem("name");
  const storedLastname = localStorage.getItem("lastname");
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const initials =
    storedName &&
    storedName.trim() !== "" &&
    storedLastname &&
    storedLastname.trim() !== ""
      ? storedName.charAt(0).toUpperCase() +
        storedLastname.charAt(0).toUpperCase()
      : "HF";

  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:330px)");

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    const token = localStorage.getItem("token");
    const customerID = localStorage.getItem("customerID");
    console.log("token: ", token, "customerID: ", customerID);
    handleMenuClose();
    if (token && customerID) {
      try {
        const response = await Services.SetLanguage(token, customerID, lng);
        console.log("SetLanguage başarılı:", response.data);
      } catch (error) {
        console.error("SetLanguage API çağrısı başarısız:", error);
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Container>
        <Box>
          <Grid container alignItems="center" sx={{ paddingBlock: "8px" }}>
            {type === "backButton" && (
              <>
                <Grid item xs={1}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="goBack"
                      size="small"
                      color="pure"
                      onClick={handleGoBack}
                    >
                      <ArrowBackIosNew fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{ fontWeight: 700 }}
                    >
                      Hands Free
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        bgcolor: "rgba(242, 244, 247, 1)",
                        border: "1px solid rgba(208, 213, 221, 1)",
                        borderRadius: "16px",
                        cursor: "pointer",
                        padding: "2px 8px",
                        marginRight: 2,
                      }}
                      onClick={handleMenuClick}
                    >
                      <img
                        src={language === "tr" ? Turkiye : UnitedKingdom}
                        width={isSmallScreen ? 20 : 30}
                        style={{ marginRight: 8 }}
                      />
                      <ArrowDropDown />
                    </Box>
                    <Avatar
                      sx={{
                        bgcolor: "rgba(242, 244, 247, 1)",
                        width: isSmallScreen ? 24 : 32,
                        height: isSmallScreen ? 24 : 32,
                        cursor: "pointer",
                        border: "solid",
                        borderWidth: "1px",
                        borderRadius: 16,
                        borderColor: "rgba(208, 213, 221, 1)",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: isSmallScreen ? "10.75px" : "14.75px",
                          fontSize: isSmallScreen ? 8 : 12,
                          fontWeight: 600,
                          color: "rgba(71, 84, 103, 1)",
                        }}
                      >
                        {initials}
                      </Typography>
                    </Avatar>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(setLanguage("en"));
                          changeLanguage("en");
                        }}
                      >
                        English
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch(setLanguage("tr"));
                          changeLanguage("tr");
                        }}
                      >
                        Türkçe
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </>
            )}

            {type === "logo" && (
              <>
                <Grid item xs={1}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        bgcolor: "rgba(242, 244, 247, 1)",
                        border: "1px solid rgba(208, 213, 221, 1)",
                        borderRadius: "16px",
                        cursor: "pointer",
                        padding: "5px 4px",
                      }}
                      onClick={handleMenuClick}
                    >
                      <img
                        src={language === "tr" ? Turkiye : UnitedKingdom}
                        width={isSmallScreen ? 15 : 25}
                      />
                      <ArrowDropDown />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={Logo}
                      alt="easypoint logo"
                      width={isSmallScreen ? 160 : 180}
                      style={{ marginTop: 5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Avatar
                      sx={{
                        bgcolor: "rgba(242, 244, 247, 1)",
                        width: isSmallScreen ? 24 : 32,
                        height: isSmallScreen ? 24 : 32,
                        cursor: "pointer",
                        border: "solid",
                        borderWidth: "1px",
                        borderRadius: 16,
                        borderColor: "rgba(208, 213, 221, 1)",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: isSmallScreen ? "10.75px" : "14.75px",
                          fontSize: isSmallScreen ? 8 : 12,
                          fontWeight: 600,
                          color: "rgba(71, 84, 103, 1)",
                        }}
                      >
                        {initials}
                      </Typography>
                    </Avatar>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(setLanguage("en"));
                          changeLanguage("en");
                        }}
                      >
                        English
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch(setLanguage("tr"));
                          changeLanguage("tr");
                        }}
                      >
                        Türkçe
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>

      <Box sx={{ width: "100vw", position: "relative", left: 0 }}>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#e0e0e0",
            marginTop: "1px",
          }}
        />
      </Box>
    </>
  );
}
