import {
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import { useSelector } from "react-redux";
import Language from "../languages";
import Layout from "../components/layout/Layout.";
import { LeftIconButton } from "../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function MainPage() {
  const navigate = useNavigate();
  const projectName = useSelector((state) => state.projectName);
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);

  const uri = localStorage.getItem("mallUri");
  const storedMall = localStorage.getItem("mall");
  const [loginType, setLoginType] = useState("2");
  const [formData, setFormData] = useState({
    firm: "",
    name: "",
    lastname: "",
    phone: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [additionalNote, setAdditionalNote] = useState("");
  const [mall, setMall] = useState();
  const [disabledPhone, setDisabledPhone] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [showRoomNo, setShowRoomNo] = useState(false);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSuccess = () => {
    setSuccess(true);
    setLoading(false);
  };

  const handleFailed = () => {
    setFailed(true);
    setLoading(false);
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreate = (token, name, lastname, phone, firm, additionalNote) => {
    Services.CreateOrder(
      token,
      name,
      lastname,
      phone,
      firm,
      `${name} ${lastname} ${additionalNote}`
    )
      .then((res) => {
        if (res.data.status) {
          handleSuccess();
        } else {
          handleFailed();
        }
      })
      .catch((err) => {
        handleFailed();
      });
  };

  const handleContinue = () => {
    const { name, firm, lastname, phone, checkbox1, checkbox2 } = formData;
    setLoading(true);
    handleCreate(token, name, lastname, phone, firm, additionalNote);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedName = localStorage.getItem("name");
    const storedLastname = localStorage.getItem("lastname");
    const storedPhone = localStorage.getItem("phone");
    setToken(storedToken);
    if (storedMall) {
      const parsedMall = JSON.parse(storedMall);
      if (parsedMall[0]?.uri === "istinyepark-izmir") {
        setShowRoomNo(true);
      }
      setMall(parsedMall);
    } else {
      navigate("/select-mall");
    }
    if (storedName) {
      setFormData((prevData) => ({
        ...prevData,
        name: storedName,
        lastname: storedLastname,
        phone: storedPhone,
      }));
      setDisabledPhone(true);
    } else {
      navigate("/login");
    }
  }, [storedMall]);

  return (
    <Layout type={"backButton"} redBg={isWideScreen}>
      {isWideScreen && (
        <Box
          display="flex"
          sx={{ maxWidth: "550px", marginBottom: "20px" }}
          justifyContent="space-between"
          alignItems="center"
          marginX={"auto"}
        >
          <IconButton
            aria-label="goBack"
            size="small"
            onClick={() => navigate(-1)}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "6px",
              padding: "8px",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              backgroundColor: "white",
            }}
          >
            <ArrowBackIcon sx={{ color: "#3f3f3f", fontSize: "20px" }} />
          </IconButton>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
          >
            {Language(_mall, language, "welcome")}
          </Typography>
          <Box />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: isWideScreen ? "white" : "transparent",
          borderRadius: isWideScreen ? "6px" : "0px",
          borderColor: isWideScreen ? "rgba(241, 235, 235, 1)" : "transparent",
          border: isWideScreen ? "1px" : "none",
          maxWidth: isWideScreen ? "550px" : null,
          padding: isWideScreen ? 3 : 0,
          marginBottom: isWideScreen ? "300px" : "0px",
          marginX: isWideScreen ? "auto" : "0",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          {Language(_mall, language, "createHandsfree", projectName)}
        </Typography>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "300",
            color: "rgba(102, 112, 133, 1)",
          }}
        >
          {Language(_mall, language, "selectStoreTitle")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            marginBlock: "15px",
          }}
        />

        <FormControl fullWidth>
          <Typography
            sx={{
              lineHeight: "16.94px",
              fontSize: 12,
              fontWeight: 500,
              marginBlock: 1,
              color: "rgba(17, 25, 40, 1)",
            }}
          >
            {Language(_mall, language, "selectStore")}
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder={Language(_mall, language, "firm")}
            name="firm"
            value={formData.firm}
            onChange={handleInputChange}
            sx={{
              marginBottom: 1,
              backgroundColor: "rgba(249, 250, 251, 1)",
              borderColor: "#C1C6C9",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "400",
              color: "rgba(107, 114, 128, 1)",
            }}
          >
            {mall &&
              mall[0].stores.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Typography
          sx={{
            lineHeight: "16.94px",
            fontSize: 12,
            fontWeight: 500,
            marginBlock: 1,
            color: "rgba(17, 25, 40, 1)",
          }}
        >
          {Language(_mall, language, "name")}
        </Typography>
        <TextField
          id="outlined-basic-1"
          variant="outlined"
          fullWidth
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          sx={{
            marginBottom: 1,
            backgroundColor: "rgba(249, 250, 251, 1)",
            borderColor: "#C1C6C9",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: "400",
            color: "rgba(107, 114, 128, 1)",
          }}
        />
        <Typography
          sx={{
            lineHeight: "16.94px",
            fontSize: 12,
            fontWeight: 500,
            marginBlock: 1,
            color: "rgba(17, 25, 40, 1)",
          }}
        >
          {Language(_mall, language, "lastname")}
        </Typography>
        <TextField
          id="outlined-basic-2"
          variant="outlined"
          fullWidth
          name="lastname"
          value={formData.lastname}
          onChange={handleInputChange}
          sx={{
            marginBottom: 1,
            backgroundColor: "rgba(249, 250, 251, 1)",
            borderColor: "#C1C6C9",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: "400",
            color: "rgba(107, 114, 128, 1)",
          }}
        />
        <Typography
          sx={{
            lineHeight: "16.94px",
            fontSize: 12,
            fontWeight: 500,
            marginBlock: 1,
            color: "rgba(17, 25, 40, 1)",
          }}
        >
          {Language(_mall, language, "phone")}
        </Typography>
        <TextField
          id="outlined-basic-3"
          variant="outlined"
          placeholder="5XXXXXXXXX"
          fullWidth
          name="phone"
          disabled={disabledPhone}
          value={formData.phone}
          onChange={handleInputChange}
          InputLabelProps={{
            style: { color: "#C1C6C9", fontSize: 15 },
          }}
          sx={{
            marginBottom: showRoomNo ? 1 : 3,
            backgroundColor: "rgba(249, 250, 251, 1)",
            borderColor: "#C1C6C9",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: "400",
            color: "rgba(107, 114, 128, 1)",
          }}
        />
        {showRoomNo && (
          <>
            <Typography
              sx={{
                lineHeight: "16.94px",
                fontSize: 12,
                fontWeight: 500,
                marginBlock: 1,
                color: "rgba(17, 25, 40, 1)",
              }}
            >
              {Language(_mall, language, "roomNo")}
            </Typography>
            <TextField
              id="outlined-basic-4"
              variant="outlined"
              fullWidth
              name="roomNo"
              value={additionalNote}
              onChange={(e) => setAdditionalNote(e.target.value)}
              InputLabelProps={{
                style: { color: "#C1C6C9", fontSize: 15 },
              }}
              sx={{
                marginBottom: 5,
                backgroundColor: "rgba(249, 250, 251, 1)",
                borderColor: "#C1C6C9",
                borderRadius: "6px",
                fontSize: "14px",
                fontWeight: "400",
                color: "rgba(107, 114, 128, 1)",
              }}
            />
          </>
        )}

        {_mall === "galataport" && (
          <FormGroup fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  {...label}
                  checked={formData.checkbox3}
                  onChange={handleInputChange}
                  name="checkbox3"
                  defaultChecked
                  color="newRed"
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#5E596D",
                    lineHeight: "19.5px",
                    fontSize: 13,
                    fontWeight: 600,
                  }}
                >
                  {Language(_mall, language, "taxFree")}
                </Typography>
              }
              sx={{ marginBlock: 2 }}
            />
          </FormGroup>
        )}

        <LeftIconButton
          text={Language(_mall, language, "continueAndCreate")}
          loading={loading}
          disabled={
            (loginType !== "2" &&
              (loading ||
                formData.name === "" ||
                formData.lastname === "" ||
                formData.firm === "")) ||
            (loginType !== "2" && showRoomNo && additionalNote === "")
          }
          handlePress={handleContinue}
        />
        <Typography
          sx={{
            color: "black",
            fontSize: 8,
            marginTop: "15px",
            marginBottom: isWideScreen ? "20px" : "200px",
            textAlign: "center",
          }}
        >
          {Language(_mall, language, "version")} 2.0.0
        </Typography>
      </Box>

      <Dialog open={success} onClose={() => setSuccess(false)}>
        <DialogTitle color={"green"}>
          {Language(_mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(_mall, language, "successMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccess(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {Language(_mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={failed} onClose={() => setFailed(false)}>
        <DialogTitle color={"red"}>
          {Language(_mall, language, "error")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(_mall, language, "errorMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFailed(false)} color="primary">
            {Language(_mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
