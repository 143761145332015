import React, { useState } from "react";
import { Typography, Box, Divider, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Language from "../languages";
import { encrypt } from "../utils/encrypt.js";
import Layout from "../components/layout/Layout..js";
import { PrimaryButton } from "../components/Button.js";

export default function RegisterPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const uri = localStorage.getItem("mallUri");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const phone = location?.state?.phone;
  const [loading, setLoading] = useState(false);

  const handleContinue = () => {
    localStorage.setItem("name", name);
    localStorage.setItem("lastname", lastname);
    localStorage.setItem("phone", phone);
    console.log("Hashed Code: ", encrypt(phone, "handsfreeAvm*24"));
    localStorage.setItem("hashedCode", encrypt(phone, "handsfreeAvm*24"));
    navigate(`/${uri}`);
  };

  return (
    <Layout type={"backButton"}>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "welcome", projectName)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "signIn")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            // display: { xs: "block", md: "none" },
            marginBlock: "15px",
          }}
        />
        <Box
          sx={{
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            // textAlign="center"
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 600,
              marginBlock: 1,
              color: "#475467",
            }}
          >
            {Language(mall, language, "enterInformations")}
          </Typography>
        </Box>

        <Typography
          // textAlign="center"
          sx={{
            lineHeight: "16.94px",
            fontSize: 12,
            fontWeight: 500,
            marginBlock: 1,
            color: "rgba(52, 64, 84, 1)",
          }}
        >
          {Language(mall, language, "name")}
        </Typography>
        <TextField
          id="outlined-basic-1"
          placeholder={Language(mall, language, "name")}
          variant="outlined"
          fullWidth
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            style: { color: "#C1C6C9", fontSize: 15 },
          }}
          sx={{
            marginBottom: 2,
            backgroundColor: "#ffffff",
            borderColor: "#C1C6C9",
          }}
        />
        <Typography
          // textAlign="center"
          sx={{
            lineHeight: "16.94px",
            fontSize: 12,
            fontWeight: 500,
            marginBlock: 1,
            color: "rgba(52, 64, 84, 1)",
          }}
        >
          {Language(mall, language, "lastname")}
        </Typography>
        <TextField
          id="outlined-basic-2"
          placeholder={Language(mall, language, "lastname")}
          variant="outlined"
          fullWidth
          name="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          InputLabelProps={{
            style: { color: "#C1C6C9", fontSize: 15 },
          }}
          sx={{
            marginBottom: 2,
            backgroundColor: "#ffffff",
            borderColor: "#C1C6C9",
          }}
        />
        <Typography
          // textAlign="center"
          sx={{
            lineHeight: "16.94px",
            fontSize: 12,
            fontWeight: 500,
            marginBlock: 1,
            color: "rgba(52, 64, 84, 1)",
          }}
        >
          {Language(mall, language, "phone")}
        </Typography>
        <TextField
          id="outlined-basic-2"
          placeholder={Language(mall, language, "phone")}
          variant="outlined"
          fullWidth
          name="phone"
          value={phone}
          disabled
          //   onChange={(e) => setLastname(e.target.value)}
          InputLabelProps={{
            style: { color: "#C1C6C9", fontSize: 15 },
          }}
          sx={{
            marginBottom: 5,
            backgroundColor: "#ffffff",
            borderColor: "#C1C6C9",
          }}
        />

        <div style={{ marginBottom: 200 }}>
          <PrimaryButton
            text={Language(mall, language, "login")}
            loading={loading}
            disabled={loading || name?.length < 2 || lastname?.length < 2}
            handlePress={handleContinue}
          />
        </div>
      </Box>
    </Layout>
  );
}
