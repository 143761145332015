import React, { useState, useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "../styles/phoneInputStyles/phoneInput.css";
import {
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Services from "../services/Services";
import { useSelector } from "react-redux";
import Language from "../languages";
import Layout from "../components/layout/Layout..js";
import { PrimaryButton } from "../components/Button.js";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function LoginPage() {
  const navigate = useNavigate();
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showKVKKModal, setShowKVKKModal] = useState(false);

  const [agreement1, setAgreement1] = useState("");
  const [agreement2, setAgreement2] = useState("");

  const sendSMS = (phone) => {
    const _token = localStorage.getItem("token");
    setToken(_token);
    const _code = Math.floor(100000 + Math.random() * 900000).toString();
    const message =
      language === "tr"
        ? `Telefon numaranızı doğrulamanız için ${_code} kodunu gerekli alana giriniz.`
        : `Enter code ${_code} in the required field to verify your phone number.`;
    Services.SendSMS(_token, phone, message)
      .then((res) => {
        navigate("/otp", { state: { token: _token, phone, code: _code } });
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const handleContinue = () => {
    setLoading(true);
    sendSMS(phone);
  };

  useEffect(() => {
    Services.GetToken().then((res) => {
      Services.GetAgreement(res.data.result.token, "1", language).then(
        (response) => {
          console.log("1", response.data);
          setAgreement1(response.data.result);
        }
      );
      Services.GetAgreement(res.data.result.token, "2", language).then(
        (response) => {
          console.log("2", response.data);
          setAgreement2(response.data.result);
        }
      );
    });
  }, [language]);

  return (
    <Layout type={"backButton"}>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "welcome", projectName)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "signIn")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            // display: { xs: "block", md: "none" },
            marginBlock: "15px",
          }}
        />
        <Box
          sx={{
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            // textAlign="center"
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 600,
              marginBlock: 1,
              color: "#475467",
            }}
          >
            {Language(mall, language, "enterPhone")}
          </Typography>
        </Box>
        <PhoneInput
          defaultCountry="tr"
          value={phone}
          onChange={(phone) => setPhone(phone)}
          inputProps={{
            id: "phone-input",
            name: "phone",
            placeholder: Language(mall, language, "phone"),
          }}
          inputStyle={{
            width: "100%",
            marginBottom: 40,
            backgroundColor: "#ffffff",
            borderColor: "#C1C6C9",
          }}
        />

        <FormGroup style={{ marginBottom: 30 }} fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox2}
                onChange={() => {
                  if (checkbox2 === false) setShowKVKKModal(true);
                  else setCheckbox2(false);
                }}
                name="checkbox2"
                {...label}
                defaultChecked
                color="newRed"
                sx={{
                  alignSelf: "flex-start",
                  color: "rgba(209, 213, 219, 1)",
                  "&.Mui-checked": {
                    color: "rgba(233, 36, 52, 0.8)",
                  },
                }} // Adjust alignment here
              />
            }
            label={
              <>
                {language === "tr" ? (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "rgba(107, 114, 128, 1)",
                      lineHeight: "19.5px",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    Avm Lojistik Tic. A.Ş şirketi’nden almış olduğum hizmet
                    kapsamında, 6698 sayılı kanun uyarınca, bilgime sunulan
                    Aydınlatma Metnini okudum, anladım, onaylıyorum.
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "rgba(107, 114, 128, 1)",
                      lineHeight: "19.5px",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    Avm Lojistik Tic. A.Ş. within the scope of the service I
                    have received from the company, in accordance with the law
                    numbered 6698, I have read, understood and approved the
                    Clarification Text submitted to my knowledge.
                  </Typography>
                )}
              </>
            }
            sx={{ marginBottom: 3 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                {...label}
                checked={checkbox3}
                onChange={() => {
                  setCheckbox3(!checkbox3);
                }}
                name="checkbox3"
                defaultChecked
                color="newRed"
                sx={{
                  alignSelf: "flex-start",
                  color: "rgba(209, 213, 219, 1)",
                  "&.Mui-checked": {
                    color: "rgba(233, 36, 52, 0.8)",
                  },
                }} // Adjust alignment here
              />
            }
            label={
              <Typography
                variant="subtitle1"
                sx={{
                  color: "rgba(107, 114, 128, 1)",
                  lineHeight: "19.5px",
                  fontSize: 13,
                  fontWeight: 400,
                }}
              >
                {Language(mall, language, "consent")}
              </Typography>
            }
          />
        </FormGroup>

        <div style={{ marginBottom: 200 }}>
          <PrimaryButton
            text={Language(mall, language, "continue")}
            loading={loading}
            disabled={loading || !checkbox2 || !checkbox3}
            handlePress={handleContinue}
          />
        </div>
      </Box>

      <Dialog
        open={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        maxWidth="lg"
      >
        <DialogTitle>
          {language === "tr" ? "Koşullar" : "Conditions"}
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: agreement2 }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCheckbox1(true);
              setShowTermsModal(false);
            }}
            color="primary"
          >
            {Language(mall, language, "agree")}
          </Button>
          <Button onClick={() => setShowTermsModal(false)} color="primary">
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* KVKK Modal */}
      <Dialog
        open={showKVKKModal}
        onClose={() => setShowKVKKModal(false)}
        maxWidth="lg"
      >
        <DialogTitle>
          {language === "tr" ? "KVKK Politikası" : "KVKK Policy"}
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: agreement1 }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCheckbox2(true);
              setShowKVKKModal(false);
            }}
            color="primary"
          >
            {Language(mall, language, "agree")}
          </Button>
          <Button onClick={() => setShowKVKKModal(false)} color="primary">
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
