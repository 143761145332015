// export const BASE_URL = "https://handsfreeapi.com";
export const BASE_URL = "https://stage.handsfreeapi.com";

export const KEY = "likEFOTAmapmDwWdBcFf";
export const SECRET = "IUT6nGY30ucth6HKXXSb";

// default key secret
export const DEFAULT_KEY = "CeQJ3t6OT8Ka5RTiDPU1tBepbUQumY";
export const DEFAULT_SECRET = "4VtrWHHHrWYmnYUlOHncj7DSlapfhA";

// exchange api
// export const TCMB_BASE_URL = "https://api.exchangerate-api.com/v4/latest/EUR";
export const TCMB_BASE_URL = "https://hasanadiguzel.com.tr/api/kurgetir";
