// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-international-phone-country-selector-button {
  height: 50px;
  width: 60px;
}

.react-international-phone-input-container .react-international-phone-input {
  height: 50px;
}

.css-i4bv87-MuiSvgIcon-root {
  /* color: rgba(209, 213, 219, 1); */
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/phoneInputStyles/phoneInput.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;AACpB","sourcesContent":[".react-international-phone-country-selector-button {\n  height: 50px;\n  width: 60px;\n}\n\n.react-international-phone-input-container .react-international-phone-input {\n  height: 50px;\n}\n\n.css-i4bv87-MuiSvgIcon-root {\n  /* color: rgba(209, 213, 219, 1); */\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
