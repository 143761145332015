import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { useNavigate } from "react-router-dom";
import Services from "../services/Services";
import { useSelector } from "react-redux";
import Language from "../languages";
import Layout from "../components/layout/Layout.";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowForwardIos, Refresh as RefreshIcon } from "@mui/icons-material";

const IconWrapper = styled(Box)({
  backgroundColor: "rgba(218, 56, 49, 0.2)",
  borderRadius: "16px",
  width: "50px",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "16px",
  marginTop: "2px",
  marginLeft: "15px",
});

export default function PackagesInTransitListPage() {
  const navigate = useNavigate();
  const mall = useSelector((state) => state.mall);
  const projectName = useSelector((state) => state.projectName);
  const language = useSelector((state) => state.language);
  const token = localStorage.getItem("token");
  const [allPackages, setAllPackages] = useState([]);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  const findLocationID = (orderID) => {
    const matchedRequest = allPackages.find(
      (request) => request.orderID === orderID
    );
    return matchedRequest ? matchedRequest.locationID : "Lokasyon Bulunamadı";
  };

  const fetchPackages = async () => {
    setLoading(true);
    try {
      const tokenInfo = await Services.GetTokenInfo(token);
      console.log("tokenInfo: ", tokenInfo);
      const mallData = JSON.parse(localStorage.getItem("mall"));
      const mallID = mallData[0]?.id;
      const customerID = localStorage.getItem("customerID");

      if (mallID && customerID) {
        const response = await Services.CustomerPosts(
          token,
          customerID,
          mallID
        );
        if (response.data.status) {
          setAllPackages(response.data.result);
          const filteredPackages = response.data.result.filter(
            (item) => item.status === "4" || item.status === "7"
          );
          console.log(filteredPackages);
          setPackages(filteredPackages);
        } else {
          setPackages([]);
        }
      }
    } catch (err) {
      console.error(err);
      setPackages([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <Layout type={"backButton"} redBg={isWideScreen}>
      {isWideScreen && (
        <Box
          display="flex"
          sx={{ maxWidth: "550px", marginBottom: "20px" }}
          justifyContent="space-between"
          alignItems="center"
          marginX={"auto"}
        >
          <IconButton
            aria-label="goBack"
            size="small"
            onClick={() => navigate(-1)}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "6px",
              padding: "8px",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              backgroundColor: "white",
            }}
          >
            <ArrowBackIcon sx={{ color: "#3f3f3f", fontSize: "20px" }} />
          </IconButton>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              color: "white",
              flex: 1,
              textAlign: "center",
            }}
          >
            {Language(mall, language, "welcome")}
          </Typography>
          <IconButton
            aria-label="refresh"
            size="small"
            onClick={fetchPackages}
            sx={{
              border: "1px solid #ffffff",
              borderRadius: "24px",
              padding: "8px",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              backgroundColor: "transparent",
            }}
          >
            <RefreshIcon sx={{ color: "white", fontSize: "20px" }} />
          </IconButton>
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: isWideScreen ? "white" : "transparent",
          borderRadius: isWideScreen ? "6px" : "0px",
          borderColor: isWideScreen ? "rgba(241, 235, 235, 1)" : "transparent",
          border: isWideScreen ? "1px" : "none",
          maxWidth: isWideScreen ? "550px" : null,
          padding: isWideScreen ? 3 : 0,
          marginBottom: isWideScreen ? "300px" : "0px",
          marginX: isWideScreen ? "auto" : "0",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          {Language(mall, language, "onthewayPackages")}
        </Typography>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "300",
            color: "rgba(102, 112, 133, 1)",
          }}
        >
          {Language(mall, language, "onthewayPackagesText")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            marginBlock: "15px",
          }}
        />
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <CircularProgress sx={{ color: "rgba(233, 36, 52, 1)" }} />
          </Box>
        ) : (
          <>
            {packages?.length > 0 ? (
              <>
                {packages.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => {
                      navigate("/post-details", {
                        state: {
                          ...item,
                          locationID: findLocationID(item.orderID),
                        },
                      });
                    }}
                    style={{
                      display: "flex",
                      height: 73,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#ffffff",
                      elevation: 4,
                      border: "1px solid  rgba(225, 231, 236, 1)",
                      shadowColor: "#092420",
                      shadowOpacity: 0.13,
                      shadowOffset: {
                        width: 0,
                        height: 4,
                      },
                      shadowRadius: 10,
                      borderRadius: 8,
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconWrapper>
                        <NearMeOutlinedIcon
                          sx={{ color: "rgba(218, 56, 49, 1)" }}
                        />
                      </IconWrapper>

                      <Box>
                        <Typography
                          sx={{
                            lineHeight: "16.94px",
                            fontSize: 14,
                            fontWeight: 600,
                            marginBottom: "5px",
                            color: "rgba(52, 64, 84, 1)",
                          }}
                        >
                          {`${item.branchTitle} - (${item.orderNumber})`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(7, 148, 85, 1)",
                            lineHeight: "14.52px",
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          {Language(mall, language, "ontheway")}
                        </Typography>
                      </Box>
                    </Box>

                    <IconButton
                      aria-label="goBack"
                      size="small"
                      color="pure"
                      sx={{ marginRight: 1 }}
                    >
                      <ArrowForwardIos fontSize="inherit" />
                    </IconButton>
                  </Box>
                ))}
              </>
            ) : (
              <Typography
                variant="subtitle1"
                sx={{
                  lineHeight: "16.34px",
                  fontSize: 12,
                  fontWeight: 600,
                  marginBottom: 5,
                  color: "gray",
                }}
              >
                {Language(mall, language, "emptyOnthewayPackages", projectName)}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Layout>
  );
}
