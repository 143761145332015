import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";
import * as Icons from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const IconWrapper = styled(Box)({
  backgroundColor: "rgba(218, 56, 49, 0.2)",
  borderRadius: "16px",
  width: "55px",
  height: "55px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "16px",
  marginTop: "8px",
  marginLeft: "6px",
});

const subtitleText = {
  tr: {
    storeRequests: "Mağazadan onay bekleyen {badge} adet talebiniz vardır.",
    customerRequests: "Onay vermeniz gereken {badge} adet talebiniz vardır.",
    storePackages: "Mağazada {badge} adet paketiniz bulunmaktadır.",
    packagesOnTheRoad: "Yolda {badge} adet paketiniz bulunmaktadır.",
    readyPackages: "Teslime hazır {badge} adet paketiniz bulunmaktadır.",
    pastRequests: "Geçmişte {badge} adet talebiniz bulunmaktadır.",
  },
  en: {
    storeRequests: "You have {badge} waiting for approval from the store.",
    customerRequests: "You have {badge} requests to approve.",
    storePackages: "You have {badge} packages in the store.",
    packagesOnTheRoad: "You have {badge} packages on the road.",
    readyPackages: "{badge} packages are ready for delivery.",
    pastRequests: "You have {badge} past requests.",
  },
};

function CustomCard({ title, badge, subtitle, icon, type, language, path }) {
  const navigate = useNavigate();
  const IconComponent = Icons[icon];

  const getSubtitle = () => {
    if (badge > 0) {
      const textTemplate = subtitleText[language][type] || subtitle;
      return (
        <>
          {textTemplate.split("{badge}")[0]}
          <Typography
            component="span"
            sx={{ color: "rgba(218, 56, 49, 1)", fontWeight: "bold" }}
          >
            {badge}
          </Typography>
          {textTemplate.split("{badge}")[1]}
        </>
      );
    }
    return subtitle;
  };

  return (
    <Card
      sx={{ height: "130px", display: "flex", alignItems: "center" }}
      onClick={() => navigate(`/${path}`)}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <IconWrapper>
            {IconComponent ? (
              <IconComponent sx={{ color: "rgba(218, 56, 49, 1)" }} />
            ) : null}
          </IconWrapper>
          <Box sx={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(52, 64, 84, 1)",
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                {title}
              </Typography>
              {badge > 0 && (
                <Box
                  sx={{
                    backgroundColor: "rgba(218, 56, 49, 1)",
                    width: 20,
                    height: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 10,
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    sx={{ color: "white", fontSize: 12, fontWeight: "600" }}
                  >
                    {badge}
                  </Typography>
                </Box>
              )}
            </div>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: "400",
                color: "rgba(128, 159, 184, 1)",
              }}
            >
              {getSubtitle()}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CustomCard;
