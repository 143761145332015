import React, { useState } from "react";
import "react-international-phone/style.css";
import "../styles/phoneInputStyles/phoneInput.css";
import { Typography, Box, Divider } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Services from "../services/Services";
import { useSelector } from "react-redux";
import Language from "../languages";
import { encrypt } from "../utils/encrypt.js";
import Layout from "../components/layout/Layout..js";
import { PrimaryButton } from "../components/Button.js";
import OtpInput from "react-otp-input";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function OTPPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const uri = localStorage.getItem("mallUri");
  const phone = location.state.phone;
  const [code, setCode] = useState(location.state.code);
  const [otp, setOtp] = useState("");
  const token = location.state.token;
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userControl = () => {
    Services.GetCustomer(token, phone)
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("name", res.data.result.name);
          localStorage.setItem("lastname", res.data.result.lastname);
          localStorage.setItem("phone", res.data.result.phone);
          localStorage.setItem("customerID", res.data.result.customerID);
          localStorage.setItem(
            "hashedCode",
            encrypt(res.data.result.phone, "handsfreeAvm*24")
          );
          navigate(`/${uri}`);
        } else {
          // giriş bilgileri
          navigate(`/register`, {
            state: {
              phone: phone,
            },
          });
        }
      })
      .catch((err) => {
        window.alert(Language(mall, language, "errorMessage"));
      })
      .finally(() => setLoading(false));
  };

  const sendSMS = () => {
    const _code = Math.floor(100000 + Math.random() * 900000).toString();
    setCode(_code);
    const message =
      language === "tr"
        ? `Telefon numaranızı doğrulamanız için ${_code} kodunu gerekli alana giriniz.`
        : `Enter code ${_code} in the required field to verify your phone number.`;
    Services.SendSMS(token, phone, message)
      .then((res) => {})
      .catch((err) => {});
  };

  const handleContinue = () => {
    setLoading(true);
    if (otp === code) {
      userControl();
    } else {
      window.alert(
        language === "tr"
          ? "Kodlar uyuşmuyor, tekrar deneyiniz."
          : "Codes don't match, please try again."
      );
      setLoading(false);
    }
  };

  return (
    <Layout type={"backButton"}>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "welcome", projectName)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "signIn")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            marginBlock: "15px",
          }}
        />
        <Box
          sx={{
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              lineHeight: "16.94px",
              fontSize: 16,
              fontWeight: 600,
              marginTop: 1,
              color: "rgba(71, 84, 103, 1)",
            }}
          >
            {Language(mall, language, "OTPCode")}
          </Typography>
          <Typography
            sx={{
              lineHeight: "16.94px",
              fontSize: 12,
              fontWeight: 400,
              marginBlock: 1,
              color: "rgba(71, 84, 103, 1)",
            }}
          >
            {Language(mall, language, "enterOTP")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "30px",
            }}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{
                width: isMobile ? "2rem" : "3rem",
                height: "3rem",
                margin: "0 0.5rem",
                fontSize: isMobile ? "1rem" : "1.5rem",
                borderRadius: 8,
                border: "1px solid rgba(0, 0, 0, 0.15)",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </Box>
        </Box>

        <Box
          sx={{ flexDirection: "row", display: "flex", marginBottom: "80px" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              color: "rgba(153, 153, 153, 1)",
              fontWeight: "400",
            }}
          >
            {Language(mall, language, "hasCode")}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              cursor: "pointer",
              color: "black",
              fontWeight: "600",
              marginLeft: "5px",
              textDecorationLine: "underline",
            }}
            onClick={sendSMS}
          >
            {Language(mall, language, "codeAgain")}
          </Typography>
        </Box>

        <div style={{ marginBottom: 200 }}>
          <PrimaryButton
            text={Language(mall, language, "continue")}
            loading={loading}
            disabled={loading || otp?.length < 6}
            handlePress={handleContinue}
          />
        </div>
      </Box>
    </Layout>
  );
}
