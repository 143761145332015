import {
  Container,
  Typography,
  Avatar,
  IconButton,
  Button,
  Box,
  Chip,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Package1 from "../assets/images/package1.png";
import { useState, useEffect } from "react";
import Services from "../services/Services";
import {
  AddCircleOutline,
  ArrowForwardIos,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setProjectName, setMall } from "../redux/appReducer";
import Language from "../languages";
import { setLanguage } from "../redux/appReducer";
import { encrypt } from "../utils/encrypt";
import Layout from "../components/layout/Layout.";
import { RightIconButton } from "../components/Button";
import Content from "../components/menuContent/Content";

export default function PostListingPage() {
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const hashedCode = localStorage.getItem("hashedCode");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const phoneNumber = "0850 214 0044";
  const [token, setToken] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [user, setUser] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [requests, setRequests] = useState([]);
  const [storesPackages, setStoresPackages] = useState([]);
  const [porterPackages, setPorterPackages] = useState([]);
  const [readyPackages, setReadyPackages] = useState([]);
  const [pastRequests, setPastRequests] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [storeNames, setStoreNames] = useState({});

  const [storeRequestsLength, setStoreRequestsLength] = useState(0);
  const [customerRequestsLength, setCustomerRequestsLength] = useState(0);
  const [inStorePackagesLength, setInStorePackagesLength] = useState(0);
  const [inTransitPackagesLength, setInTransitPackagesLength] = useState(0);
  const [readyForDeliveryPackagesLength, setReadyForDeliveryPackagesLength] =
    useState(0);
  const [pastRequestsLength, setPastRequestsLength] = useState(0);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const [lastFetchTime, setLastFetchTime] = useState(
    new Date().toLocaleString("en-GB")
  );
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePhoneNumberClick = () => {
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      const textField = document.createElement("textarea");
      textField.innerText = phoneNumber;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      document.body.removeChild(textField);
    }
  };

  // const findLocationID = (orderID) => {
  //   const matchedRequest = allRequests.find(
  //     (request) => request.orderID === orderID
  //   );
  //   return matchedRequest ? matchedRequest.locationID : "Lokasyon Bulunamadı";
  // };

  const findStoreName = (branchID) => {
    Services.GetLocationList(token, branchID).then((res) => {
      const matchedItem = res.data.result.find(
        (item) => item.branchID === branchID
      );
      setStoreNames((prevStoreNames) => ({
        ...prevStoreNames,
        [branchID]: matchedItem.title,
      }));
    });
    // .catch((err) => console.log(err));
  };

  useEffect(() => {
    pastRequests.forEach((item) => {
      findStoreName(item.branchID);
    });
  }, [pastRequests]);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return moment(date).format("DD.MM.YYYY HH:mm");
  };

  const findPosts = (order) => {
    const matchedPosts = [];
    for (const post of allPosts) {
      if (post.orderID === order.orderID) {
        matchedPosts.push(post);
      }
    }
    navigate("past-request-details", {
      state: {
        postList: matchedPosts,
        locationID: order.locationID,
      },
    });
  };

  const handleRefresh = () => {
    setForceRefresh(true);
  };

  useEffect(() => {
    if (forceRefresh) {
      fetchDataAndRequests();
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  const fetchPosts = (token, customerID, mallID) => {
    console.log("posts için mallID: ", mallID);
    Services.CustomerPosts(token, customerID, mallID)
      .then((res) => {
        const packages = res.data.result;
        const stores = packages?.filter((item) => item.status === "3");
        const porter = packages?.filter(
          (item) => item.status === "4" || item.status === "7"
        );
        const ready = packages?.filter((item) => item.status === "5");

        setAllPosts(packages);
        setStoresPackages(stores);
        setInStorePackagesLength(stores?.length);
        setPorterPackages(porter);
        setInTransitPackagesLength(porter?.length);
        setReadyPackages(ready);
        setReadyForDeliveryPackagesLength(ready?.length);
      })
      .finally(() => setShowLoading(false));
  };

  const fetchCustomerOrders = (
    storedToken,
    storedCustomerID,
    mallID,
    orderId
  ) => {
    console.log("storedCustomerID: ", storedCustomerID, "mallID: ", mallID);
    if (orderId) {
      Services.CustomerOrders(storedToken, storedCustomerID).then(
        (response) => {
          if (response.data.status) {
            setAllRequests(response.data.result);
            const matchedOrder = response.data.result.find(
              (obj) => obj.orderID === orderId
            );
            navigate("/create-new", { state: matchedOrder });
          } else {
            setRequests([]);
            setPastRequests([]);
            setShowLoading(false);
          }
        }
      );
    } else {
      console.log("orders için mallID: ", mallID);
      Services.CustomerOrdersByMallID(storedToken, storedCustomerID, mallID)
        .then((response) => {
          if (response.data.status) {
            setAllRequests(response.data.result);
            const currentDate = new Date().toLocaleDateString("tr-TR");
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const yesterdayDate = yesterday.toLocaleDateString("tr-TR");

            const completedList = response.data.result.filter(
              (item) => item.isCompleted === "1"
            );

            const normalStatusList = response.data.result.filter(
              (item) =>
                (item.status === "3" || item.status === "6") &&
                item.isCompleted === "0"
            );

            const filteredRequests = response.data.result.filter(
              (item) =>
                (item.status === "1" || item.status === "2") &&
                new Date(item.createdDateTime * 1000).toLocaleDateString(
                  "tr-TR"
                ) === currentDate
            );

            const pastRejecteds = response.data.result.filter(
              (item) =>
                item.status === "2" &&
                new Date(+item.createdDateTime * 1000).toLocaleDateString(
                  "tr-TR"
                ) < yesterdayDate
            );

            let combinedList = [];
            let pastList = [];

            if (normalStatusList?.length > 0 && filteredRequests?.length > 0) {
              combinedList = normalStatusList.concat(filteredRequests);
            } else {
              combinedList =
                normalStatusList?.length > 0
                  ? normalStatusList
                  : filteredRequests;
            }

            combinedList = combinedList.filter(
              (item) => item.isCompleted !== "1"
            );

            const storeRequestCount = combinedList?.filter(
              (item) => item.status === "3"
            )?.length;
            const customerRequestCount = combinedList?.filter(
              (item) => item.status === "6"
            )?.length;

            setStoreRequestsLength(storeRequestCount);
            setCustomerRequestsLength(customerRequestCount);

            setRequests(combinedList);
            if (completedList?.length > 0 && pastRejecteds?.length > 0) {
              pastList = completedList.concat(pastRejecteds);
            } else {
              pastList =
                completedList?.length > 0 ? completedList : pastRejecteds;
            }
            setPastRequests(pastList);
            setPastRequestsLength(pastList?.length);
            setShowLoading(false);
          } else {
            setRequests([]);
            setPastRequests([]);
            setShowLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequests([]);
          setShowLoading(false);
        });
    }
  };

  const fetchDataAndRequests = () => {
    setShowLoading(true);
    setLastFetchTime(new Date().toLocaleString("en-GB"));
    const match = window.location.pathname.match(/\/([^\/]*)\//);
    const urlParams = new URLSearchParams(window.location.search);
    const url_token = urlParams.get("token");
    const languageParam = urlParams.get("language") || language;
    const fullURL = window.location.search;

    const userIDIndex = fullURL.indexOf("userID=");
    let url_customerID = "";

    if (userIDIndex !== -1) {
      const endIndex = fullURL.indexOf("&", userIDIndex + "userID=".length);
      url_customerID =
        endIndex === -1
          ? fullURL.substring(userIDIndex + "userID=".length)
          : fullURL.substring(userIDIndex + "userID=".length, endIndex);
    }

    // akıllı avm
    if (url_token && url_customerID) {
      console.log("url token: ", url_token, "url_customerID: ", url_customerID);
      localStorage.setItem("mallUri", match[1]);
      if (match && match[1] === "galataport") {
        dispatch(setProjectName("Bags-Free"));
        dispatch(setMall("galataport"));
      } else {
        dispatch(setProjectName("Hands Free"));
        dispatch(setMall("default"));
      }
      setToken(url_token);
      dispatch(setLanguage(languageParam));
      Services.ThirdPartyCustomerQuery(
        url_token,
        url_customerID,
        match[1],
        languageParam
      )
        .then((res) => {
          console.log("third party response: ", res.data);
          localStorage.setItem("token", url_token);
          localStorage.setItem("name", res.data.result.name);
          localStorage.setItem("lastname", res.data.result.lastname);
          localStorage.setItem("phone", res.data.result.phone);
          localStorage.setItem("customerID", res.data.result.customerID);
          localStorage.setItem("email", res.data.result.email);
          localStorage.setItem(
            "hashedCode",
            encrypt(res.data.result.phone, "handsfreeAvm*24")
          );
          setUser(true);
          // avm mağazaları kaydedildi
          Services.GetTokenInfo(url_token)
            .then((response) => {
              fetchPosts(
                url_token,
                res.data.result.customerID,
                response.data.result.mall[0].id
              );
              fetchCustomerOrders(
                url_token,
                res.data.result.customerID,
                response.data.result.mall[0].id
              );
              localStorage.setItem(
                "mall",
                JSON.stringify(response.data.result.mall)
              );
            })
            .catch((error) => {
              console.log(error);
              localStorage.setItem("mall", JSON.stringify(""));
            });
        })
        .catch((err) => {
          console.log(err);
          localStorage.setItem("name", "");
          localStorage.setItem("lastname", "");
          localStorage.setItem("phone", "");
          localStorage.setItem("customerID", "");
          localStorage.setItem("email", "");
          localStorage.setItem("mall", JSON.stringify(""));
          setShowLoading(false);
        });
    }
    // akıllı avm değil
    else {
      console.log("Akıllı avm değil");
      Services.GetDefaultToken()
        .then((res) => {
          const newToken = res.data.result.token;
          setToken(newToken);
          localStorage.setItem("token", res.data.result.token);

          const url = window.location.href;
          const regex = /\/([^\/]*)$/;
          const onlyAvm = url.match(regex);
          const urlParts = url.split("/");
          const path = urlParts[urlParts.length - 2]; // path'i al
          const orderId = urlParts[urlParts.length - 1]; // orderId'yi al
          console.log("path: ", path, "orderID: ", orderId);
          // order-confirm mi diye kontrol edilmeli
          if (path && path === "order-confirm") {
            console.log("order confirm'e girdi");
            const controlPhone = localStorage.getItem("phone");
            if (
              controlPhone &&
              encrypt(controlPhone, "handsfreeAvm*24") == hashedCode
            ) {
              Services.GetCustomer(res.data.result.token, controlPhone)
                .then((_response) => {
                  console.log("get customer: ", _response.data);
                  localStorage.setItem("name", _response.data.result.name);
                  localStorage.setItem(
                    "lastname",
                    _response.data.result.lastname
                  );
                  localStorage.setItem("phone", _response.data.result.phone);
                  localStorage.setItem(
                    "customerID",
                    _response.data.result.customerID
                  );
                  dispatch(setLanguage(_response.data.result.defaultLanguage)); // Burada gelen language değeri ile güncelleyeceğiz.
                  fetchCustomerOrders(
                    newToken,
                    _response.data.result.customerID,
                    "",
                    orderId
                  );
                })
                .catch((err) => {
                  console.log("get customer hata: ", err);
                });
            } else {
              userNotFound();
              setShowLoading(false);
            }
          } else if (orderId && orderId === "null") {
            localStorage.removeItem("mall");
            localStorage.removeItem("mallUri");
            navigate("select-mall");
          } else {
            if (onlyAvm && onlyAvm[1]) {
              const afterSlashValue = onlyAvm[1];
              console.log("onlyavm: ", onlyAvm[1]);
              if (onlyAvm[1] === "galataport") {
                dispatch(setProjectName("Bags-Free"));
                dispatch(setMall("galataport"));
              } else {
                dispatch(setProjectName("Hands Free"));
                dispatch(setMall("default"));
              }
              localStorage.setItem("mallUri", onlyAvm[1]);
              Services.GetMallToken(newToken, afterSlashValue)
                .then((response) => {
                  localStorage.setItem(
                    "mall",
                    JSON.stringify(response.data.result.mall)
                  );
                  const controlPhone = localStorage.getItem("phone");
                  if (controlPhone) {
                    console.log(
                      "only avm: ",
                      encrypt(controlPhone, "handsfreeAvm*24"),
                      hashedCode
                    );
                    if (
                      encrypt(controlPhone, "handsfreeAvm*24") == hashedCode
                    ) {
                      Services.GetCustomer(newToken, controlPhone)
                        .then((_response) => {
                          console.log("get customer: ", _response.data);
                          console.log("only avm'ye girdi");

                          localStorage.setItem(
                            "name",
                            _response.data.result.name
                          );
                          localStorage.setItem(
                            "lastname",
                            _response.data.result.lastname
                          );
                          localStorage.setItem(
                            "phone",
                            _response.data.result.phone
                          );
                          localStorage.setItem(
                            "customerID",
                            _response.data.result.customerID
                          );
                          dispatch(
                            setLanguage(_response.data.result.defaultLanguage)
                          );
                          setShowLoading(false);
                          setUser(true);
                          fetchPosts(
                            newToken,
                            _response.data.result.customerID,
                            response.data.result.mall[0].id
                          );
                          if (path && path === "order-confirm") {
                            fetchCustomerOrders(
                              newToken,
                              _response.data.result.customerID,
                              response.data.result.mall[0].id,
                              orderId
                            );
                            localStorage.setItem(
                              "mall",
                              JSON.stringify(response.data.result.mall)
                            );
                          } else {
                            localStorage.setItem(
                              "mall",
                              JSON.stringify(response.data.result.mall)
                            );
                            fetchCustomerOrders(
                              newToken,
                              _response.data.result.customerID,
                              response.data.result.mall[0].id
                            );
                          }
                        })
                        .catch((err) => {
                          console.log("get customer hata: ", err);
                          setShowLoading(false);
                        });
                    } else {
                      // Sorunlu deneme
                      userNotFound();
                      setShowLoading(false);
                    }
                  } else {
                    setShowLoading(false);
                  }
                })
                .catch(() => {
                  console.log("mall token hata");
                  setShowLoading(false);
                });
            } else {
              localStorage.removeItem("mall");
              localStorage.removeItem("mallUri");
              navigate("select-mall");
              setShowLoading(false);
            }
          }
        })
        .catch(() => {
          console.log("default token hata");
          setShowLoading(false);
        });
    }
  };

  const userNotFound = () => {
    setUser(false);
    localStorage.clear();
  };

  useEffect(() => {
    if (!user) {
      const intervalId = setInterval(() => {
        fetchDataAndRequests();
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    fetchDataAndRequests();
  }, []);

  useEffect(() => {
    const fullURL = window.location.search;
    const userIDIndex = fullURL.indexOf("userID=");

    const urlParams = new URLSearchParams(window.location.search);
    const url_token = urlParams.get("token");
    let url_customerID = "";

    if (userIDIndex !== -1) {
      const endIndex = fullURL.indexOf("&", userIDIndex + "userID=".length);
      url_customerID =
        endIndex === -1
          ? fullURL.substring(userIDIndex + "userID=".length)
          : fullURL.substring(userIDIndex + "userID=".length, endIndex);
    }
    // user control
    Services.GetDefaultToken()
      .then((res) => {
        const newToken = res.data.result.token;
        setToken(newToken);
        localStorage.setItem("token", res.data.result.token);
        const controlPhone = localStorage.getItem("phone");
        console.log("useEffect Control: ", controlPhone);
        if (
          controlPhone &&
          encrypt(controlPhone, "handsfreeAvm*24") == hashedCode
        ) {
          setUser(true);
        } else {
          if (url_token && url_customerID) {
            setUser(true);
          } else {
            setUser(false);
            navigate("/select-mall");
          }
        }
        // setShowLoading(false);
      })
      .catch(() => {
        setShowLoading(false);
      });
  }, []);

  return (
    <Layout type={"logo"} redBg={isWideScreen} fullWidth>
      <Box sx={{ my: 2 }}>
        {showLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        ) : (
          <>
            <>
              {isWideScreen && (
                <Box
                  display="flex"
                  sx={{ maxWidth: "700px", marginBottom: "20px" }}
                  justifyContent="space-between"
                  alignItems="center"
                  marginX={"auto"}
                >
                  <Box />

                  <Typography
                    sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
                  >
                    {Language(mall, language, "welcome")}
                  </Typography>
                  <IconButton
                    aria-label="goBack"
                    size="small"
                    onClick={handleRefresh}
                    sx={{
                      border: "1px solid #ffffff",
                      borderRadius: "24px",
                      padding: "8px",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      // backgroundColor: "t",
                    }}
                  >
                    <RefreshIcon sx={{ color: "white", fontSize: "20px" }} />
                  </IconButton>
                </Box>
              )}
              <Box
                sx={{
                  maxWidth: isWideScreen ? "700px" : null,
                  marginX: isWideScreen ? "auto" : "0",
                }}
              >
                <Container
                  sx={{
                    paddingInline: isWideScreen ? "0px !important" : "24px",
                  }}
                >
                  <RightIconButton
                    text={Language(
                      mall,
                      language,
                      "createHandsfree",
                      projectName
                    )}
                    handlePress={() => navigate("create-request")}
                    opposite={isWideScreen}
                  />
                </Container>

                <Content
                  storeRequestsLength={storeRequestsLength}
                  customerRequestsLength={customerRequestsLength}
                  inStorePackagesLength={inStorePackagesLength}
                  inTransitPackagesLength={inTransitPackagesLength}
                  readyForDeliveryPackagesLength={
                    readyForDeliveryPackagesLength
                  }
                  pastRequestsLength={pastRequestsLength}
                />
              </Box>
            </>
          </>
        )}
      </Box>
    </Layout>
  );
}
