import {
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";
import Layout from "../components/layout/Layout.";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FaintButton, LeftIconButton } from "../components/Button";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function CreateNewPostPage() {
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const item = location.state;
  const token = localStorage.getItem("token");
  const uri = localStorage.getItem("mallUri");
  const [count, setCount] = useState();
  const [success, setSuccess] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);
  const [euroRate, setEuroRate] = useState(35);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const euro = 5;
  const tl = 175;
  const dollar = 6;

  const padWithZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = padWithZero(currentDate.getDate());
    const month = padWithZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    return `${day}.${month}.${year}`;
  };
  const deadlineDate = getCurrentDate();
  const deadlineTime = "22:00";

  const confirm = () => {
    setLoadingConfirm(true);
    Services.CustomerOrderConfirm(token, item?.customerID, item?.orderID)
      .then((res) => {
        if (res.data.status) {
          setSuccess(true);
        } else {
          setFailed(true);
        }
      })
      .catch((err) => {
        setFailed(true);
      })
      .finally(() => {
        setLoadingConfirm(false);
      });
  };

  const reject = () => {
    setLoadingReject(true);
    Services.CustomerOrderReject(token, item.customerID, item.orderID)
      .then((res) => {
        if (res.data.status) {
          setRejectModal(true);
        } else {
          setFailed(true);
        }
      })
      .catch((err) => {
        setFailed(true);
      })
      .finally(() => {
        setLoadingReject(false);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("item: ", item);
    const storedMall = localStorage.getItem("mall");
    const parsedMall = JSON.parse(storedMall);
    if (parsedMall[0].paymentType === "mall-pay") {
      setHidePrice(true);
    }

    Services.CustomerPosts(token, item?.customerID)
      .then((res) => {
        const filteredPosts = res.data.result.filter(
          (post) => post.orderID === item.orderID
        );
        setCount(filteredPosts);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });

    // Services.GetExchangeRate()
    //   .then((res) => {
    //     console.log(res.data);
    //     // const rate = res.data.rates.TRY;
    //     const euroCurrency = res.data.TCMB_AnlikKurBilgileri.find(
    //       (currency) => currency.Isim === "EURO"
    //     );
    //     if (euroCurrency) {
    //       setEuroRate(euroCurrency.BanknoteSelling);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching exchange rate:", error);
    //   });
  }, [token, item?.customerID, item?.orderID]);

  return (
    <Layout type={"backButton"} redBg={isWideScreen}>
      {isWideScreen && (
        <Box
          display="flex"
          sx={{ maxWidth: "550px", marginBottom: "20px" }}
          justifyContent="space-between"
          alignItems="center"
          marginX={"auto"}
        >
          <IconButton
            aria-label="goBack"
            size="small"
            onClick={() => navigate(-1)}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "6px",
              padding: "8px",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              backgroundColor: "white",
            }}
          >
            <ArrowBackIcon sx={{ color: "#3f3f3f", fontSize: "20px" }} />
          </IconButton>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
          >
            {Language(mall, language, "welcome")}
          </Typography>
          <Box />
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: isWideScreen ? "white" : "transparent",
          borderRadius: isWideScreen ? "6px" : "0px",
          borderColor: isWideScreen ? "rgba(241, 235, 235, 1)" : "transparent",
          border: isWideScreen ? "1px" : "none",
          maxWidth: isWideScreen ? "550px" : null,
          padding: isWideScreen ? 3 : 0,
          marginBottom: isWideScreen ? "300px" : "0px",
          marginX: isWideScreen ? "auto" : "0",
        }}
      >
        <Typography
          textAlign="left"
          sx={{
            lineHeight: "16.94px",
            fontSize: 14,
            fontWeight: 600,
            // marginX: 2,
          }}
        >
          {Language(mall, language, "pendingPackages")}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            // textAlign: { xs: "left", md: "center" },
          }}
        >
          {Language(mall, language, "pendingPackagesDescription")}
        </Typography>
        <Divider
          sx={{
            color: "#EAECF0",
            marginBlock: "25px",
          }}
        />
        {count?.map((order) => (
          <TextField
            id="outlined-basic"
            label={`${order?.orderNumber}`}
            variant="outlined"
            fullWidth
            disabled
            InputLabelProps={{
              style: {
                color: "rgba(102, 112, 133, 1)",
                fontSize: 14,
                fontWeight: "600",
              },
            }}
            sx={{
              marginBottom: 2,
              backgroundColor: "rgba(249, 250, 251, 1)",
              borderColor: "rgba(209, 213, 219, 1)",
              borderWidth: 1,
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: 1,
              },
            }}
          />
        ))}
        {mall === "galataport" && (
          <Typography
            variant="subtitle1"
            sx={{
              marginBlock: 2,
              lineHeight: "19.49px",
              color: "rgba(152, 162, 179, 1)",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {language === "tr"
              ? `*Fiyat Bilgisi: Hizmet bedeli poşet başına ${euro} euro veya ${dollar} dolar veya ${tl} tl olarak ücretlendirilmiştir.`
              : `*Price Information: The service fee is charged as ${euro} euros or ${dollar} dollars or ${tl} TL per bag.`}
          </Typography>
        )}

        <FormGroup fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                {...label}
                defaultChecked
                sx={{
                  color: "rgba(209, 213, 219, 1)",
                  "&.Mui-checked": {
                    color: "rgba(233, 36, 52, 1)",
                  },
                }}
                checked={checkbox3}
                onChange={() => setCheckbox3(!checkbox3)}
              />
            }
            label={
              <Typography
                variant="subtitle1"
                sx={{
                  lineHeight: "19.49px",
                  fontSize: 12,
                  fontWeight: 500,
                  color: "rgba(71, 84, 103, 1)",
                }}
              >
                {Language(mall, language, "timeLimit")}
              </Typography>
            }
            sx={{ marginBottom: 2 }}
          />

          {!hidePrice && (
            <FormControlLabel
              control={
                <Checkbox
                  {...label}
                  defaultChecked
                  sx={{
                    color: "rgba(209, 213, 219, 1)",
                    "&.Mui-checked": {
                      color: "rgba(233, 36, 52, 1)",
                    },
                  }}
                  checked={checkbox2}
                  onChange={() => setCheckbox2(!checkbox2)}
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "19.49px",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "rgba(71, 84, 103, 1)",
                  }}
                >
                  {language === "tr"
                    ? `Toplam ${euro * count?.length} EU / ${
                        dollar * count?.length
                      } USD / ${tl * count?.length} TL gönderi ücretini
                    onaylıyorum.`
                    : `I agree to a total service fee of ${
                        euro * count?.length
                      } EU / ${dollar * count?.length} USD / ${
                        tl * count?.length
                      } TL`}
                </Typography>
              }
              sx={{ marginBottom: 15 }}
            />
          )}
        </FormGroup>

        <FaintButton
          sx={{ marginBottom: "25px" }}
          text={Language(mall, language, "cancel")}
          loading={loadingReject}
          disabled={loadingReject || loadingConfirm}
          handlePress={reject}
          faint
        />

        <LeftIconButton
          text={Language(mall, language, "confirm")}
          loading={loadingConfirm}
          disabled={
            loadingReject ||
            loadingConfirm ||
            !checkbox3 ||
            (!hidePrice && !checkbox2)
          }
          handlePress={confirm}
        />
      </Box>

      <Dialog open={success}>
        <DialogTitle color={"green"}>
          {Language(mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Language(mall, language, "confirmSuccessMessage")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccess(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {Language(mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={rejectModal}>
        <DialogTitle color={"Green"}>
          {Language(mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Language(mall, language, "rejectSuccessMessage")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRejectModal(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {Language(mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={failed} onClose={() => setFailed(false)}>
        <DialogTitle color={"red"}>
          {Language(mall, language, "error")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(mall, language, "errorMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFailed(false)} color="primary">
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
